import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import algoliasearch from 'algoliasearch';
import {
  Box,
  Button,
  Divider,
  FormHelperText,
  Grid,
  Skeleton,
} from '@mui/material';
import {
  PoneaTVIcon,
  ProductDetailsPlaceHolder,
  RatingStar,
} from '../../assets/images';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import SpecialitiesBooking from './SpecialitiesBooking';
import {
  AlgoliaFilter,
  BottomDrawer,
  LoaderMain,
  ResultNotFound,
  VideoModal,
} from '../../components';
import { algolia } from '../../env';
import {
  Configure,
  InstantSearch,
  useHits,
} from 'react-instantsearch-hooks-web';
import { SuperSEO } from 'react-super-seo';
import { pageSEO } from '../../seo';
import Context from '../../context';
import { _currencyFormatter } from '../../utils/Index';
import {
  checkWishlistItems,
  removeItemFromWishlist,
  wishlistHandler,
} from '../../utils/WishlistHandler';
import { LoadingButton } from '@mui/lab';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorderOutlined';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { useDispatch, useSelector } from 'react-redux';
import { authAction } from '../../store';
import CompareFAQ from './FAQ';
import { _clickAnalyticsUpdate } from '../../utils/API_SERVICE/_concierge';
import GetGuidance from './GetGuidance';
import DesktopFilter from './DesktopFilter';
import ButtonDefault from '../../components/commons/Button/ButtonDefault';
import './compare.scss';
import { pathBuilder } from '../../urlPaths';
import ElevateAppBar from '../../components/commons/CompareNavBar';

//This component use for specialities card of doctor, service, clinic

const SpecialitiesCard = ({ name, images, rast_rating, ...props }: any) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(
    (state: any) => state.auth.isAuthenticated
  );

  const [wishlistErr, setWishlistErr] = useState<string>('');
  const [wishlistState, setWishlistState] = useState<boolean>(false);

  const checkItemInWishlist = () => {
    checkWishlistItems(props.id)
      .then((res) => {
        setWishlistState(res);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    checkItemInWishlist();
  }, []);

  const wishlistItemHandler = () => {
    if (isAuthenticated) {
      props.addWishlistState(true);
      setWishlistErr('');
      if (!wishlistState) {
        wishlistHandler(
          { name, images, rast_rating, ...props },
          1,
          props.item_details.item_endpoint
        )
          .then((res) => {
            props.addWishlistState(false);
            setWishlistState(true);
          })
          .catch((err) => {
            console.log(err);
            props.addWishlistState(false);
            setWishlistErr(
              err?.response?.data?.message ||
                err.message ||
                'Something went wrong, please try again later.'
            );
          });
      } else {
        removeItemFromWishlist(props.id)
          .then((response) => {
            props.addWishlistState(false);
            setWishlistState(false);
          })
          .catch((err) => {
            console.log(err);
            props.addWishlistState(false);
            setWishlistErr(
              err?.response?.data?.message ||
                err.message ||
                'Something went wrong, please try again later.'
            );
          });
      }
    } else {
      dispatch(authAction.accesspermisson());
    }
  };

  const [videoModal, setVideoModal] = useState<boolean>(false);
  const DetailsClickHandler = () => {
    let item_type = props.item_details.item_type.toLowerCase();
    let group_name = props.group.name.toLowerCase();
    
    if (item_type === 'specialist') {
      navigate(
        `/doctor-details/${props.provider_details.name
          .replaceAll('.', '')
          .replaceAll(' ', '-')
          .toLowerCase()}/${props.id}`
      );
    } else if (item_type === 'packages') {
      navigate(`/package-details/package/facility-package/${props.id}`);
      navigate(
        pathBuilder.packageDetails(
          props.id,
          props.item_details?.compare_endpoint,
          props.item_details?.item_endpoint
        )
      );

    } else if (group_name === 'medical supplies') {
      navigate(`/products/${props.slug}`);
    } else if (item_type === 'services') {
      navigate(
        pathBuilder.serviceDetails(
          props.id,
          props.item_details?.compare_endpoint,
          props.item_details?.item_endpoint
        )
      );
    } else {
      props.openBooking({ name, images, rast_rating, ...props });
    }

    _clickAnalyticsUpdate({
      item_id: props.id,
      item_type: props.item_details?.item_endpoint,
    })
      .then((res) => console.log(res))
      .catch((err) => console.log(err));
  };

  const GetButtonLabel = () => {
    let item_type = props.item_details.item_type.toLowerCase();
    let group_name = props.group.name.toLowerCase();

    if (item_type === 'specialist') return 'Book Specialist';
    if (item_type === 'packages') return 'Book Package';
    if (item_type === 'products') return 'Buy Product';
    if (group_name === 'medical supplies') return 'Buy Now';

    return 'Book Care';
  };

  return (
    <>
      <Box className="compare-card position-relative">
        <p className="option-number">{props.optionNo + 1}</p>
        <div className="d-flex ">
          <div
            className="doctor-img-container flex-shrink-0"
            style={{ width: 110, height: 110 }}
          >
            <img
              className="img-fluid"
              src={
                props.provider_details?.profile_url
                  ? props.provider_details.profile_url
                  : ProductDetailsPlaceHolder
              }
              alt=""
            />
          </div>
          <div className="doctor-details ml-2">
            <p className="fs-13 color_dark-grey">Provider</p>
            <p className="font-weight_5 pr-6 package-heading-clamp">
              {props.provider_details.name}
            </p>
            <div className="d-flex justify-content-between mt-2">
              <div>
                <div className="d-flex fs-14 align-items-center">
                  <FmdGoodIcon />
                  <span>{props.provider_details.location}</span>
                </div>
              </div>
              {props.provider_details?.rast_score ? (
                <div className="mt-2">
                  <p className="fs-10">Ponea Ratings</p>
                  <div className="d-flex">
                    <img
                      src={RatingStar}
                      alt=""
                      style={{ width: 45 }}
                      className="mr-1"
                    />
                    <span>{props.provider_details?.rast_score}</span>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>

        {/* show service type with price */}

        <div className="d-flex justify-content-between align-items-center mb-2 mt-4">
          <Grid container spacing={2}>
            <Grid item md={12} lg={4}>
              <small
                style={{
                  color: '#4B5563',
                  fontWeight: 'bold',
                }}
              >
                Facility Visit
              </small>
              <p className="compare-price">
                {(props.rates.facility_visit ?? 0) > 0
                  ? `${_currencyFormatter(props.rates.facility_visit)}.00`
                  : 'N/A'}
              </p>
            </Grid>
            <Grid
              item
              md={6}
              lg={4}
              style={{
                textAlign: 'center',
              }}
            >
              <small
                style={{
                  color: '#4B5563',
                  fontWeight: 'bold',
                }}
              >
                Teleconsult
              </small>
              <p className="compare-price">
                {(props.rates.teleconsult ?? 0) > 0
                  ? `${_currencyFormatter(props.rates.teleconsult)}.00`
                  : 'N/A'}
              </p>
            </Grid>
            <Grid
              item
              md={6}
              lg={4}
              style={{
                textAlign: 'right',
              }}
            >
              <small
                style={{
                  color: '#4B5563',
                  fontWeight: 'bold',
                }}
              >
                Home Visit
              </small>
              <p className="compare-price">
                {(props.rates.home_visit ?? 0) > 0
                  ? `${_currencyFormatter(props.rates.home_visit)}.00`
                  : 'N/A'}
              </p>
            </Grid>
          </Grid>
        </div>
        <Divider />
        <Box className="mt-2">
          <div className="p-0 d-flex">
            <div className="btn-section">
              <ButtonDefault
                btnClass={'bg_transparent border_primary w-100'}
                title={
                  wishlistState ? 'Remove from Wishlist' : 'Add to Wishlist'
                }
                classes={'mt-2 w-100 mx-auto'}
                click={wishlistItemHandler}
                icon={wishlistState ? <FavoriteIcon /> : <FavoriteBorderIcon />}
              />

              <ButtonDefault
                title={GetButtonLabel()}
                classes="ml-2 mt-2 w-100 mx-auto"
                click={DetailsClickHandler}
              />
            </div>
          </div>

          {props.provider_details?.videos.length ? (
            <div
              className="cursor-pointer d-flex flex-column"
              onClick={() => setVideoModal(true)}
            >
              <p className="fs-13 color_dark-grey mt-2">Videos on ponea TV</p>
              <img src={PoneaTVIcon} width="100px" alt="" />
            </div>
          ) : null}
          {wishlistErr && <FormHelperText error>{wishlistErr}</FormHelperText>}
          {props.requestor && (
            <>
              <p className="fs-13 color_dark-grey mt-2">Requestor</p>
              <p className="fs-15">{props.requestor}</p>
            </>
          )}

          <div className="flex-shrink-0">
            {props.item_details.compare_endpoint !== 'service' ? (
              Object.entries(props.provider_details).map(
                ([key, value]: any, index) =>
                  value &&
                  typeof value === 'string' &&
                  ![
                    'id',
                    'name',
                    'profile_url',
                    'geolocation',
                    'location',
                  ].includes(key) ? (
                    <div key={index}>
                      <p className="fs-13 color_dark-grey mt-2 text-capitalize">
                        {key === 'gender_name'
                          ? 'Gender'
                          : key.replaceAll('_', ' ')}
                      </p>
                      <p>{value}</p>
                    </div>
                  ) : null
              )
            ) : (
              <>
                {props.indications && (
                  <>
                    <p className="fs-13 color_dark-grey mt-2">Indication</p>
                    <p className="fs-15">{props.indications}</p>
                  </>
                )}
                {props.preparation && (
                  <>
                    <p className="fs-13 color_dark-grey mt-2">Preparation</p>
                    <p className="fs-15">{props.preparation}</p>
                  </>
                )}
                {props.sample_type && (
                  <>
                    <p className="fs-13 color_dark-grey mt-2">Sample type</p>
                    <p className="fs-15">{props.sample_type}</p>
                  </>
                )}
                {props.collection && (
                  <>
                    <p className="fs-13 color_dark-grey mt-2">
                      Location of sample Collection
                    </p>
                    <p className="fs-15">{props.collection}</p>
                  </>
                )}
                {props.technology && (
                  <>
                    <p className="fs-13 color_dark-grey mt-2">
                      Imaging Technique
                    </p>
                    <p className="fs-15">{props.technology}</p>
                  </>
                )}
              </>
            )}
          </div>
          {props.tat && props.tat !== '0' ? (
            <>
              <p className="fs-13 color_dark-grey mt-2">Tat</p>
              <p className="fs-15">{props.tat}</p>
            </>
          ) : null}
          {props.required_for_travel && (
            <>
              <p className="fs-13 color_dark-grey mt-2">Required for travel</p>
              <p className="fs-15">{props.required_for_travel}</p>
            </>
          )}
          {props.side_effects && (
            <>
              <p className="fs-13 color_dark-grey mt-2">Side effects</p>
              <p className="fs-15">{props.side_effects}</p>
            </>
          )}
        </Box>
      </Box>
      {props.provider_details?.videos.length
        ? console.log(
            props.provider_details?.videos[0].substring(
              props.provider_details?.videos[0].indexOf('=') + 2
            )
          )
        : null}
      {props.provider_details?.videos.length ? (
        <VideoModal
          open={videoModal}
          videoLink={`https://www.youtube.com/embed/${props.provider_details?.videos[0].substring(
            props.provider_details?.videos[0].indexOf('=') + 1
          )}?autoplay=1`}
          onCloseModal={() => setVideoModal(false)}
        />
      ) : null}
    </>
  );
};

const HitsCard = (props: any) => {
  const { hits, results }: any = useHits();
  const [wishlistLoading, setWishlistLoading] = useState<boolean>(false);
  const wishlistLoaderHandler = (state: boolean) => setWishlistLoading(state);

  const [drawerState, setDrawerState] = useState<boolean>(false);
  const [itemDetails, setItemDetails] = useState<any>(null);

  const openBooking = (item: string) => {
    setItemDetails(item);
    setDrawerState(true);
  };

  const closeDrawer = () => {
    setDrawerState(false);
  };

  return (
    <>
      <div className="compare-card-conatiner">
        {hits?.length > 0 ? (
          <>
            <CompareFAQ endpoint={hits[0].item_details?.item_type} />
            <Grid item xs={12} className="mb-3">
              <p className="available-opt-label">
                Options available:{' '}
                {hits.filter((item: any) => item.active)?.length}
              </p>
            </Grid>
          </>
        ) : (
          ''
        )}
        {results!.processingTimeMS > 0 && (
          <Grid container spacing={2.5}>
            {hits
              .filter((activeItem: any) => activeItem.active)
              .map((item: any, index: number) => (
                <Grid item xs={12} md={6} lg={4} key={index}>
                  <SpecialitiesCard
                    {...item}
                    optionNo={index}
                    openBooking={openBooking}
                    addWishlistState={wishlistLoaderHandler}
                  />
                </Grid>
              ))}
          </Grid>
        )}
      </div>
      {drawerState && (
        <BottomDrawer state={drawerState} click={closeDrawer}>
          <Context.Provider
            value={{
              data: {
                info: { ...itemDetails },
                click: closeDrawer,
              },
            }}
          >
            <SpecialitiesBooking />
          </Context.Provider>
        </BottomDrawer>
      )}
      {wishlistLoading && <LoaderMain state={wishlistLoading} />}
    </>
  );
};

const Loading = (props: any) => {
  const { results } = useHits();
  return (
    <>
      {results!.processingTimeMS <= 0 && (
        <Grid container spacing={2.5} mt={1}>
          <Grid item xs={12} md={6} lg={4}>
            <div className="doctor-details d-flex">
              <div className="mr-4" style={{ width: 120 }}>
                <Skeleton
                  animation="wave"
                  variant="rectangular"
                  width={'100%'}
                  height={'100%'}
                />
              </div>
              <div className="w-100">
                <Skeleton
                  animation="wave"
                  height={14}
                  width="75%"
                  style={{ marginBottom: 2 }}
                />
                <Skeleton
                  animation="wave"
                  height={10}
                  width="95%"
                  style={{ marginBottom: 3 }}
                />
                <Skeleton animation="wave" height={10} width="50%" />
                <div className="d-flex align-items-center justify-content-between mt-3">
                  <Skeleton animation="wave" height={40} width="75px" />
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      )}
    </>
  );
};

const Title = () => {
  const { hits }: any = useHits();

  return (
    <>
      {hits?.length ? (
        <>
          <div className="compare-detail-title flex-column flex-sm-row">
            <div className="img-container">
              <img src={hits[0]?.images.main} alt="" />
            </div>
            <div className="d-flex flex-column">
              <h2 className="color_primary-light fs-24 ">{hits[0]?.name}</h2>
              {hits[0]?.categories.length ? (
                <>
                  <p className="fs-18 font-weight-5 color_primary">
                    Categories
                  </p>
                  <p className="fs-14 mb-2">
                    {String(
                      hits[0]?.categories?.reduce(
                        (prev: any, cur: any) => [...prev, cur.name],
                        []
                      )
                    ).replaceAll(',', ', ')}
                  </p>
                </>
              ) : null}
              <p className="fs-15">{hits[0]?.notes}</p>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

export default function SpecialitiesDetailsCompareList(props: any) {
  const searchClient = algoliasearch(algolia.appId, algolia.apiKey);

  const { SpecialitiesService } = useParams();

  return (
    <>
      <SuperSEO
        title={pageSEO.compare.title}
        description={pageSEO.compare.description}
      />
      <Box mb={7}>
        <Divider />
        <InstantSearch
          searchClient={searchClient}
          indexName="product_index_newest"
          searchFunction={(helper) => {
            helper.setQuery(SpecialitiesService as string).search();
          }}
        >
          <Configure
            hitsPerPage={200}
            restrictSearchableAttributes={['item_details.compare_id']}
            exactOnSingleWordQuery="attribute"
          />
          <ElevateAppBar>
            <AlgoliaFilter
              classes="py-4"
              btnClass="color_primary"
              filterBtnClass="d-xl-none d-block"
            >
              <p className="color_primary-light fs-22">Compare</p>
              <GetGuidanceButton />
            </AlgoliaFilter>
          </ElevateAppBar>
          <DesktopFilter />
          <Title />
          {/* <CompareFAQ /> */}
          <HitsCard />
          <Loading />
          <ResultNotFound />
        </InstantSearch>
      </Box>
    </>
  );
}

function GetGuidanceButton() {
  const { hits }: any = useHits();
  const [drawerState, setDrawerState] = useState<boolean>(false);
  const isAuthenticated = useSelector(
    (state: any) => state.auth.isAuthenticated
  );
  const dispatch = useDispatch();

  const navigateToGuidence = () => {
    if (hits[0]?.landing_page_url && !drawerState) {
      window.open(`${hits[0].landing_page_url}`);
    } else {
      if (isAuthenticated) {
        setDrawerState(!drawerState);
      } else {
        dispatch(authAction.accesspermisson());
      }
    }
  };

  const navigateToFaqs = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <>
      <div className="ml-auto">
        <Button
          className="mr-2"
          disableElevation
          variant="outlined"
          onClick={navigateToFaqs}
        >
          FAQ
        </Button>
        <Button
          className="mr-2"
          disableElevation
          variant="contained"
          onClick={navigateToGuidence}
        >
          Get Guidence
        </Button>
      </div>
      {drawerState && (
        <BottomDrawer state={drawerState} click={navigateToGuidence}>
          <GetGuidance currentItem={hits[0]} click={navigateToGuidence} />
        </BottomDrawer>
      )}
    </>
  );
}
