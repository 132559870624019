export function GetWeekDates(startDate: Date, daysToAdd: number) {
  let aryDates = [];

  for(let i = 0; i <= daysToAdd; i++) {
    let currentDate = new Date();
      currentDate.setDate(startDate.getDate() + i);
      aryDates.push(
        {
          day: DayAsString(currentDate.getDay()),
          date: currentDate.getDate(),
          value: `${currentDate.getFullYear()}-${currentDate.getMonth() + 1}-${currentDate.getDate()}`,
          currentDate,
        }
      );
  }

  return aryDates;
}

function DayAsString(dayIndex: number) {
  var weekdays = new Array(7);
  weekdays[0] = "Sun";
  weekdays[1] = "Mon";
  weekdays[2] = "Tue";
  weekdays[3] = "Wed";
  weekdays[4] = "Thu";
  weekdays[5] = "Fri";
  weekdays[6] = "Sat";

  return weekdays[dayIndex];
}
