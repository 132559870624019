import { useContext } from 'react';
import { ServiceDetailsContext } from './ServiceDetailsPage';
import {
  ServiceImageDoctor,
  ServiceImageTab,
  ServiceMenuPlus,
} from '../../../assets/images';

function About() {
  const { serviceData } = useContext(ServiceDetailsContext);

  return (
    <div className="promoted-package-about-section">
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginBottom: '20px',
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div className="promoted-package-about-title">
            What’s In this Product?
          </div>

          {serviceData?.modality ? (
            <div className="about-item">
              <div className="about-item-name">Modality</div>
              <div className="about-item-details">{serviceData?.modality}</div>
            </div>
          ) : null}
          {serviceData?.indication ? (
            <div className="about-item">
              <div className="about-item-name">Indication</div>
              <div className="about-item-details">
                {serviceData?.indication}
              </div>
            </div>
          ) : null}
          {serviceData?.contraindications ? (
            <div className="about-item">
              <div className="about-item-name">Contraindications</div>
              <div className="about-item-details">
                {serviceData?.contraindications}
              </div>
            </div>
          ) : null}
          {serviceData?.technique ? (
            <div className="about-item">
              <div className="about-item-name">TECHNOLOGY</div>
              <div className="about-item-details">{serviceData?.technique}</div>
            </div>
          ) : null}
          {serviceData?.preparation ? (
            <div className="about-item">
              <div className="about-item-name">Preparation</div>
              <div className="about-item-details">
                {serviceData?.preparation}
              </div>
            </div>
          ) : null}
          {serviceData?.sample ? (
            <div className="about-item">
              <div className="about-item-name">Sample Type</div>
              <div className="about-item-details">{serviceData?.sample}</div>
            </div>
          ) : null}
          {serviceData?.administration ? (
            <div className="about-item">
              <div className="about-item-name">Site of Administration</div>
              <div className="about-item-details">
                {serviceData?.administration}
              </div>
            </div>
          ) : null}
          {serviceData?.tat ? (
            <div className="about-item">
              <div className="about-item-name">Turn Around Time</div>
              <div className="about-item-details">{serviceData?.tat}</div>
            </div>
          ) : null}
          {serviceData?.requester ? (
            <div className="about-item">
              <div className="about-item-name">Requester</div>
              <div className="about-item-details">{serviceData?.requester}</div>
            </div>
          ) : null}
          {serviceData?.collection ? (
            <div className="about-item">
              <div className="about-item-name">Collection</div>
              <div className="about-item-details">
                {serviceData?.collection}
              </div>
            </div>
          ) : null}
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            padding: '10px 0',
            borderBottom: '1px solid #d9d9d9',
          }}
        >
          <div className="promoted-package-about-title">Why Book with Us?</div>
          <div className="package-items-container-two">
            <div>
              <img
                className="package-item-image"
                src={ServiceMenuPlus}
                alt=""
              />
            </div>
            <div className="package-item-details">
              <div className="package-item-title">
                Home sample collection for FREE
              </div>
              <div className="package-item-long-description">
                A certified professional will collect your sample from your
                preferred location
              </div>
            </div>
          </div>
          <div className="package-items-container-two">
            <div>
              <img
                className="package-item-image"
                src={ServiceImageTab}
                alt=""
              />
            </div>
            <div className="package-item-details">
              <div className="package-item-title">
                Get digital report within 2 days
              </div>
              <div className="package-item-long-description">
                Our labs ensure turn-around-time of 48 hours from specimen
                pickup
              </div>
            </div>
          </div>
          <div className="package-items-container-two">
            <div>
              <img
                className="package-item-image"
                src={ServiceImageDoctor}
                alt=""
              />
            </div>
            <div className="package-item-details">
              <div className="package-item-title">
                Valuable doctor's consultation
              </div>
              <div className="package-item-long-description">
                Get your online reports and review them with one of our doctors
              </div>
            </div>
          </div>
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            padding: '10px 0',
            borderBottom: '1px solid #d9d9d9',
          }}
        >
          <div className="promoted-package-about-title">How it works?</div>
          <div className="package-items-container-two">
            <div>
              <img
                className="package-item-image"
                src={ServiceMenuPlus}
                alt=""
              />
            </div>
            <div className="package-item-details">
              <div className="package-item-long-description">
                Pick a package that suits your healthcare needs and seamlessly
                book a home sample collection
              </div>
            </div>
          </div>
          <div className="package-items-container-two">
            <div>
              <img
                className="package-item-image"
                src={ServiceImageDoctor}
                alt=""
              />
            </div>
            <div className="package-item-details">
              <div className="package-item-long-description">
                We will send a certified professional to your place to assist
                you with the sample collection
              </div>
            </div>
          </div>
          <div className="package-items-container-two">
            <div>
              <img
                className="package-item-image"
                src={ServiceImageTab}
                alt=""
              />
            </div>
            <div className="package-item-details">
              <div className="package-item-long-description">
                After your sample collection, you can access your reports within
                your account on our mobile application. We will also email you
                the reports
              </div>
            </div>
          </div>
          <div className="package-items-container-two">
            <div>
              <img
                className="package-item-image"
                src={ServiceImageDoctor}
                alt=""
              />
            </div>
            <div className="package-item-details">
              <div className="package-item-long-description">
                Schedule an Online consultation with a doctor to understand your
                results better and get medical advice for free
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
