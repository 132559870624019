import { useEffect, useRef, useState } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Box, FormControl, InputAdornment, InputLabel, OutlinedInput, TextField, Typography, FormGroup, FormControlLabel, Checkbox, FormHelperText } from "@mui/material";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Logo } from "../../assets/images";
import './register.scss';
import { MyIconButton, RadioButton, ButtonDefault } from "../../components";
import { Regex } from "../../utils/Regex";
import axios from "axios";
import { conciergeCred, environment } from '../../env';
import { SuperSEO } from "react-super-seo";
import { pageSEO } from "../../seo";
import { format } from "date-fns";
import { _countryList, _patientCheck } from "../../utils/API_SERVICE/_patient";
import { verify } from "crypto";

//This component use for user register

export default function Register(this: any) {
  const navigate = useNavigate();
  const [otpToken, setOtpToken] = useState<string>('');

  useEffect(() => {
    getCountryList();
    axios.post(`${environment.concierge}/api/authenticate`, conciergeCred)
    .then((response: any) => {
      setOtpToken(response.data)
    }).catch((error) => {
      console.log(error)
    })
  }, []);

  const genderDetails = [
    { title: 'Male',  id: 1 },
    { title: 'Female', id: 2 },
    // { title: 'Other', id: 3 },
  ];

  const [values, setValues] = useState({
    gender_id: 0,
    showPassword: false,
    showConfirm_Password: false,
    country_code: '91'
  });

  //get user data

  const { register, handleSubmit, formState: { errors },  watch, setValue, getValues, unregister, setError } = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: {
      first_name: '',
      middle_name: '',
      last_name: '',
      id_number: '',
      dob: '',
      birth_day: '',
      birth_month: '',
      birth_year: '',
      passport_number: '',
      mobile_number: '',
      email: '',
      gender_id: 0,
      blood_group_id: 0,
      document_type_id: 0,
      iso_code: 'KE',
      password: '',
      country_id: 115,
      country_prefix: 254,
      confirm_password: '',
      agree: ''
    }
  });

  const password = useRef({});
  password.current = watch("password", "");

  const [loading, setLoading] = useState<boolean>(false);

  const headerOTPConfig = {
    headers: {
      Authorization: `Bearer ${otpToken}`
    } 
  };

  //get mobile no with country code for send otp

  const sendOTP = (formData: any) => {
    setLoading(true);
    const data = {
      prefix: String(getValues('country_prefix')),
      number: getValues('mobile_number'),
      validity: 10
    }
    axios.post(environment.concierge + '/api/common/otp/request', data, headerOTPConfig)
    .then((response) => {
      setLoading(false);
      formData.sessionId = response.data.sessionId;
      formData.otpToken = otpToken;

      // navigate to otp verify

      navigate('/verify-otp', {state: formData});
    }).catch((error) => {
      setLoading(false);
      console.log(error)
    })
  }

  const [ isGender, setIsGender ] = useState<boolean>(true);

  const registerHandler = (data: any) => {
    if(!values.gender_id) {
      setIsGender(false)
      return
    };

    setValue('gender_id', values.gender_id);
    setValue('dob', `${getValues().birth_year}-${getValues().birth_month}-${getValues().birth_day}`);
    unregister('birth_day');
    unregister('birth_month');
    unregister('birth_year');
    unregister('agree');
    unregister('confirm_password');

    if(deplicateUser) {
      let duplicateNumEl = document.querySelector('.duplicate-number');
      duplicateNumEl?.scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      });
      return;
    };

    if(!isFutureDate) {
      let duplicateNumEl = document.querySelector('.age-error');
      duplicateNumEl?.scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      });
      return;
    };
    sendOTP(getValues());
  };

  //show password

  const handleClickShowPassword = (type: string, ev: any) => {
    if(type === 'password') {
      setValues({
        ...values,
        showPassword: !values.showPassword,
      });
    }else if(type === 'confirm_password') {
      setValues({
        ...values,
        showConfirm_Password: !values.showConfirm_Password,
      });
    }
  };

//set gender value

  const genderValue = (genderValue: any) => {
    setValues({
      ...values,
      gender_id: genderValue
    });
    setIsGender(true)
  }

  const [countryListItem, setCountryListItem] = useState<any[]>([]);

  //get country list

  const getCountryList = () => {
    _countryList().then((response) => {
      setCountryListItem(response.data?.returned_resultset);
    }).catch((err ) => {
      console.log(err)
    })
  }

  const selectCountryHandler = (ev: any) => {
    const inputInfo = JSON.parse(ev.target.value);
    setValue('iso_code', inputInfo.code);
    setValue('country_id', Number(inputInfo.id));
    setValue('country_prefix', Number(inputInfo.prefix));
    if(getValues('mobile_number')) {
      processNumChange(getValues('country_prefix') + '' + getValues('mobile_number'));
    }
  }

  const [isFutureDate, setIsFutureDate] = useState(true);

  // user 18+ age
  const ageCheckHandler = (dob: string, age = 18) => {
    var my_dob = new Date(dob);
    var today = new Date();
    var max_dob = new Date(today.getFullYear() - age, today.getMonth(), today.getDate());
    
    if(max_dob.getTime() > my_dob.getTime()){
      setIsFutureDate(true)
    }else {
      setIsFutureDate(false)
    }    
  }

  const debounce = (func: any, timeout: number = 300) => {
    let timer: NodeJS.Timeout;
    return (...args: any) => {
      clearTimeout(timer);
      timer = setTimeout(() => { func.apply(this, args); }, timeout);
    };
  }

  const [deplicateUser, setDuplicateUser] = useState<boolean>(false)
  
  //set duplicate user
  
  function saveInput(num: string){
    setDuplicateUser(false)

    if(getValues('mobile_number')) {
      _patientCheck(num, '').then((res: any) => {
        setDuplicateUser(true)
      }).catch(err => console.log(err))
    }
  }

  const processNumChange = debounce((number: string) => saveInput(number), 400);
  
  return (
    <>
      <SuperSEO
        title={pageSEO.register.title}
        description={pageSEO.register.description}
      />
      <Box px={2} className='main-container mx-w-500 my-auto'>
        <Box
          component="form"
          className="get-guide-form-container px-"
          sx={{
            '& .MuiFormGroup-root': { my: 3, width: '100%' },
            '& .MuiFormControl-root': { my: 1.4, width: '100%' },
            '& .MuiFormHelperText-root': {
              fontSize: '.7rem',
              mx: 1
            }
          }}
          autoComplete="off"
          onSubmit={handleSubmit(registerHandler)}
        >
          <NavLink to={'/'}>
            <img src={Logo} alt="logo" className={'logo'} style={{marginLeft: 0}}/>
          </NavLink>
          <Typography variant="h1" className='heading' mb={2}>
            Register Here
          </Typography>
          <p style={{marginBottom: 22}}>
            Create a Ponea Health account to get started.
          </p>
          <TextField
            label="First Name"
            placeholder="Enter your first name"
            id="firstName"
            variant="outlined"
            {...register("first_name", 
              { required: 'First name is required', 
                pattern: {
                  value: Regex.alphabet,
                  message: "Only alphabet accepted"
                },
              }
            )}
            error={errors.first_name ? true : false}
            helperText={errors.first_name &&  errors.first_name['message']}
          />
          <TextField
            label="Last Name"
            placeholder="Enter your last name"
            id="lastName"
            variant="outlined"
            {...register("last_name", 
              { required: 'Last name is required', 
                pattern: {
                  value: Regex.alphabet,
                  message: "Only alphabet accepted"
                },
              }
            )}
            error={errors.last_name ? true : false}
            helperText={errors.last_name &&  errors.last_name['message']}
          />
          <TextField
            label="Email address"
            placeholder="Enter your email"
            id="email"
            variant="outlined"
            {...register("email",
              { required: "email is required",
                pattern: {
                  value: Regex.email,
                  message: "invalid email address"
                }
              })
            }
            error={errors.email ? true : false}
            helperText={errors.email && errors.email['message']}
          />
          <div className="d-flex align-items-start">
            <select name="" id="" className="country-selectBox" onChange={selectCountryHandler.bind('ev', )}>
              {
                countryListItem.map((item, index) => {
                  return <option value={JSON.stringify(item)} key={index} selected={item?.prefix === '254'}>{item?.code} +{item?.prefix}</option>
                })
              }
            </select>
            <TextField
              label="Mobile number"
              placeholder="Enter your Mobile number"
              id="mobile_number"
              variant="outlined"
              {...register("mobile_number",
                { required: "Mobile number is required",
                  onChange: () => processNumChange(getValues('country_prefix') + '' + getValues('mobile_number')),
                  pattern: {
                    value: /^[0-9]{7,11}$/,
                    message: "Mobile number must be number"
                  }
                })
              }
              error={errors.mobile_number ? true : false || deplicateUser}
              helperText={errors.mobile_number && errors.mobile_number['message']}
            />
          </div>
            {
              deplicateUser && (
                <FormHelperText className="duplicate-number mb-3 mt-0" error>Mobile number already associated with our system, please login </FormHelperText>
              )
            }
          <p className="mt-1">Date of Birth</p>
          <div style={{display: 'flex'}}>
            <TextField
              label="Day"
              id="birth_day"
              variant="outlined"
              sx={{
                width: '100%', margin: '12px 0',
                '& .MuiOutlinedInput-input': { textAlign: 'center' },
              }}
              {...register("birth_day", 
                { required: "Day required",
                  onChange: (e) => {
                    setValue('dob', `${getValues().birth_month}-${getValues().birth_day}-${getValues().birth_year}`);
                    ageCheckHandler(getValues('dob'))
                  },
                  pattern: {
                    value: Regex.birth_day,
                    message: "Must be valid date number"
                  },
                  maxLength: {
                    value: 2,
                    message: 'Invalid date'
                  }
                })
              }
              error={errors.birth_day ? true : false}
              helperText={errors.birth_day && errors.birth_day['message']}
            />
            <TextField
              label="Month"
              id="birth_month"
              variant="outlined"
              sx={{
                width: '100%', margin: '12px',
                '& .MuiOutlinedInput-input': { textAlign: 'center' },
              }}
              {...register("birth_month", 
                { required: "Month required",
                  onChange: (e) => {
                    setValue('dob', `${getValues().birth_month}-${getValues().birth_day}-${getValues().birth_year}`);
                    ageCheckHandler(getValues('dob'))
                  },
                  pattern: {
                    value: Regex.birth_month,
                    message: "Must be valid month number"
                  }
                })
              }
              error={errors.birth_month ? true : false}
              helperText={errors.birth_month && errors.birth_month['message']}
            />
            <TextField
              label="Year"
              id="birth_year"
              variant="outlined"
              sx={{
                width: '100%', margin: '12px 0',
                '& .MuiOutlinedInput-input': { textAlign: 'center' },
              }}
              {...register("birth_year", 
                { required: "Year required",
                  onChange: (e) => {
                    setValue('dob', `${getValues().birth_month}-${getValues().birth_day}-${getValues().birth_year}`);
                    ageCheckHandler(getValues('dob'))
                  },
                  // validate: value => value === password.current || "The passwords do not match",
                  pattern: {
                    value: Regex.number,
                    message: "Must be valid year number"
                  },
                  maxLength: {
                    value: 4,
                    message: 'Invalid date'
                  },
                  minLength: {
                    value: 4,
                    message: 'Invalid date'
                  }
                })
              }
              error={errors.birth_year ? true : false || isFutureDate ? false : true}
              helperText={ errors.birth_year && errors.birth_year['message'] }
            />
          </div>
          {
            !isFutureDate && (
              <FormHelperText className="age-error mb-3 mt-0" error>Age must be 18+</FormHelperText>
            )
          }
          <p className="mt-1">Select Gender</p>
          <div className="radio-container">
            {
              genderDetails.map((item, index) => {
                return (
                  <RadioButton 
                    title={item.title} 
                    change={genderValue} 
                    value={item.title}
                    id={index+1} 
                    key={index} 
                    name={'gender'}
                    error={!isGender ? true : false}
                  />
                )
              })
            }
          </div>
            {
              !isGender && (
                <FormHelperText error className="mb-2">Please select gender.</FormHelperText>
              )
            }
          <FormControl variant="outlined">
            <InputLabel htmlFor="password">Password</InputLabel>
            <OutlinedInput
              id="password"
              type={values.showPassword ? 'text' : 'password'}
              endAdornment={
                <InputAdornment position="end">
                  <MyIconButton
                    styles={{width: 'auto', height: 'auto' }}
                    click={handleClickShowPassword.bind(this, 'password')} 
                    icon={values.showPassword ? <VisibilityOff /> : <Visibility />}
                  />
                </InputAdornment>
              }
              label="Password"
              placeholder="Enter your password"
              {...register("password", 
                { required: "Password required",
                  pattern: {
                    value: Regex.password,
                    message: "Minimum eight characters, at least one letter, one number and one special character"
                  }
                })
              }
              error={errors.password ? true : false}
            />
            {
              errors.password && (
                <FormHelperText error>
                  {errors.password && errors.password['message']}
                </FormHelperText>

              )
            }
          </FormControl>
          <FormControl variant="outlined">
            <InputLabel htmlFor="confirm_password">Confirm Password</InputLabel>
            <OutlinedInput
              id="confirm_password"
              type={values.showConfirm_Password ? 'text' : 'password'}
              endAdornment={
                <InputAdornment position="end">
                  <MyIconButton
                    styles={{width: 'auto', height: 'auto' }}
                    click={handleClickShowPassword.bind(this, 'confirm_password')} 
                    icon={values.showConfirm_Password ? <VisibilityOff /> : <Visibility />}
                  />
                </InputAdornment>
              }
              label="Confirm Password"
              placeholder="Enter your password"
              {...register("confirm_password", 
                { required: "Confirm password required",
                  validate: value => value === password.current || "The passwords do not match"
                })
              }
              error={errors.confirm_password ? true : false}
            />
            {
              errors.confirm_password && (
                <FormHelperText error>
                  {errors.confirm_password && errors.confirm_password['message']}
                </FormHelperText>

              )
            }
          </FormControl>
          <FormGroup>
            <FormControlLabel
              sx={{alignItems: 'flex-start', fontSize: 10}} 
              control={
                <Checkbox
                  sx={{padding: '0 10px'}}
                  // value={values.agree}
                  {...register("agree", { required: 'Please check agree button', })}
                />
              }
              label={<p style={{fontSize: 13}}>I agree to Ponea Health's <NavLink to={'/terms-and-conditions'} rel="noreferrer" target="_blank">Terms & Conditions</NavLink> and <NavLink to={'/privacy-policies'} target="_blank">Privacy Policy</NavLink></p>}
            />
            {
              errors.agree && (
                <FormHelperText error>
                  {errors.agree && errors.agree['message']}
                </FormHelperText>

              )
            }
          </FormGroup>
          <ButtonDefault 
            title='Register Now' 
            type='submit'
            loading={loading}
            disabled={loading}
          />
          <p style={{textAlign: 'center', display: 'block', margin: '18px 0'}}>
            Already have an account ?
            <Link to='/select-auth'>
              <strong> SignIn</strong>
            </Link>
          </p>
        </Box>
      </Box>
    </>
  )
}
