import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  OutlinedInput,
} from '@mui/material';
import UploadIcon from '@mui/icons-material/Upload';
import { ButtonDefault, MenuDrawer } from '../../components';
import {
  CalendarLeft,
  CalendarRight,
  PlaceHolderuser,
} from '../../assets/images';
import { NavLink, useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
  BannerCarousel,
  HomeBannerCarousel,
  SpecialitiesCarousel,
} from '../../components/commons/Carousel';
import { environment } from '../../env';
import OurStory from './OurStory';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import PopularPackagesAndProcedures from './PopularPackagesAndProcedures';
import DownloadApp from './DownloadApp';
import { cartAction, searchBarAction } from '../../store';
import partnerData from './partnerData';
import { SuperSEO } from 'react-super-seo';
import { pageSEO } from '../../seo';
import Context from '../../context';
import ExpressCurative from './ExpressCurative';
import ExpressOTC from './ExpressOTC';
import Featured from './Featured';
import { getUseProfileData } from '../../utils/LoggedInUser';
import {
  addToCart,
  getCart,
  getUserCartItem,
  updateLoggedInCart,
} from '../../utils/CartHandler';
import { db } from '../../db';
import DoctorsList from './DoctorsList';
import PromotedPackages from './PromotedPackages';
import {
  AppStoreDownloadIcon,
  PlayStoreDownloadIcon,
} from '../../assets/images';

const LoggedInUser = (props: any) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isAuthenticated = useSelector(
    (state: any) => state.auth.isAuthenticated
  );
  const { userInfo } = useContext(Context);
  const activeSearch = () => dispatch(searchBarAction.handlerSearchBarState());

  return (
    <>
      <div className="d-flex justify-content-between align-item-center flex-md-row flex-column">
        <div className="d-flex align-items-center mb-6">
          <div className="d-none d-md-block">
            <MenuDrawer />
          </div>
          <NavLink
            to={isAuthenticated ? '/my-profile' : '/select-auth'}
            className="ml-3"
          >
            <img
              src={userInfo?.image_url ? userInfo?.image_url : PlaceHolderuser}
              alt=""
              className="profile-image"
            />
          </NavLink>
          <NavLink to={isAuthenticated ? '/my-profile' : '/select-auth'}>
            <div className="d-flex flex-column justify-content-evenly ml-2">
              {isAuthenticated ? (
                <>
                  <p className="fs__normal-m white-space-nowrap">
                    Welcome Back
                  </p>
                  <h3 className="heading__primary color_primary-light white-space-nowrap">
                    {userInfo?.first_name}
                  </h3>
                </>
              ) : (
                <>
                  <p className="fs__normal-m white-space-nowrap">Welcome</p>
                  <h3 className="heading__primary color_primary-light white-space-nowrap">
                    Guest user
                  </h3>
                </>
              )}
            </div>
          </NavLink>
        </div>
        <Box
          component={'form'}
          autoComplete="off"
          className="mb-md-7 d-flex align-items-center flex-grow-1"
          sx={{
            '& .MuiOutlinedInput-root': {
              width: '100%',
            },
          }}
        >
          <FormControl
            sx={{ width: '100%' }}
            variant="outlined"
            className="d-none d-md-block ml-8 w-100"
          >
            <InputLabel htmlFor="search" className="d-flex">
              <SearchOutlinedIcon /> Search here
            </InputLabel>
            <OutlinedInput
              id="search"
              type={'text'}
              label="Search"
              onClick={activeSearch}
              // onKeyUp={activeSearch}
              readOnly={true}
              // disabled
            />
          </FormControl>

          <div className="nav-right-section">
            <ButtonDefault
              title={'Upload Written Request'}
              icon={<UploadIcon />}
              click={() => navigate('/prescription')}
              classes="p-3 text-nowrap"
            />

            <div className="app-download-area-top">
              <div className="download-app-section">
                <a
                  href="https://apps.apple.com/ke/app/ponea-patient/id1613582597"
                  target="_blank"
                >
                  <img 
                    src={AppStoreDownloadIcon} alt=""
                    className="ml-0"
                  />
                </a>
                <a
                  href="https://play.google.com/store/apps/details?id=com.patient.ponea.poneapatient"
                  rel="noreferrer"
                  target="_blank"
                >
                  <img src={PlayStoreDownloadIcon} alt="" />
                </a>
              </div>
            </div>
          </div>
        </Box>
      </div>
    </>
  );
};

const Home = () => {
  const isAuthenticated = useSelector(
    (state: any) => state.auth.isAuthenticated
  );

  const options: object = {
    nav: true,
    dots: true,
    center: false,
    loop: true,
    margin: 15,
    autoplay: true,
    responsive: {
      0: { items: 1 },
      767: { items: 2.3 },
      1000: { items: 3.3 },
    },
  };

  const serviceOptions: object = {
    nav: true,
    dots: false,
    loop: true,
    margin: 7,
    autoplay: false,
    center: false,
    navText: [
      `<img src=${CalendarLeft} className='rotate' alt="" />`,
      `<img src=${CalendarRight} alt="" />`,
    ],
    responsive: {
      0: { items: 3, nav: false },
      768: { items: 5.3 },
      1000: { items: 7.3 },
      1500: { items: 9.3 },
    },
  };

  const partnersOptions: object = {
    nav: true,
    dots: false,
    loop: true,
    margin: 15,
    autoplay: false,
    responsive: {
      0: { items: 2, nav: false },
      768: { items: 5 },
      1000: { items: 6 },
    },
  };

  const [specialitiesData, setSpecialitiesData] = useState<any>({
    loading: true,
    data: new Array(5).fill(''),
    isError: false,
    isCalled: false,
    isData: false,
  });

  const [healthState, setHealthState] = useState<any>({
    loading: true,
    data: new Array(5).fill(''),
    isError: false,
    errorMssg: '',
    isCalled: false,
    isData: false,
  });

  useEffect(() => {
    getBanners();

    if (!healthState.isCalled && !healthState.isData && !healthState.isError) {
      getHealthcareServices();
    }

    if (
      !specialitiesData.isCalled &&
      !specialitiesData.isData &&
      !specialitiesData.isError
    ) {
      getSpecialities();
    }
  }, [specialitiesData]);

  const getSpecialities = () => {
    setSpecialitiesData({
      loading: true,
      isCalled: true,
      data: new Array(5).fill(''),
    });
    axios
      .get(`${environment.service_catalogue}/speciality`)
      .then((response) => {
        setSpecialitiesData({
          loading: false,
          data: response['data']['returned_resultset'],
          isCalled: false,
          isData: true,
        });
      })
      .catch((error) => {
        setSpecialitiesData({
          loading: false,
          data: [],
          isCalled: true,
          isError: true,
          errorMssg: error.message,
        });
      });
  };

  const getHealthcareServices = () => {
    setHealthState({
      loading: true,
      isCalled: true,
      data: new Array(5).fill(''),
    });
    axios
      .get(`${environment.service_catalogue}/category`)
      .then((response) => {
        const finalItem = response.data.returned_resultset.map(
          (health: any) => ({
            ...health,
            title: health.name,
          })
        );
        setHealthState({
          loading: false,
          data: finalItem,
          isCalled: false,
          isData: true,
        });
      })
      .catch((error) => {
        setHealthState({
          loading: false,
          data: [],
          isCalled: true,
          isError: true,
          errorMssg: error.message,
        });
      });
  };

  const [bannerData, setBannerSet] = useState<any>({
    loading: true,
    data: new Array(5).fill(''),
  });

  const getBanners = () => {
    axios
      .get(`${environment.service_catalogue}/package?is_express=true`)
      .then((response) => {
        setBannerSet({
          loading: false,
          data: response.data.returned_resultset,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const dispatch = useDispatch();
  // Syncing cart item after user loggedin if cart item exit with userId 0
  const syncCartItem = async () => {
    const tokenData = await getUseProfileData();
    const cartData = await getCart();
    // if user localStorage has cart item then update storge item into server based on condition else fetch the cart item based on userId
    if (cartData?.length && cartData[0].user_id !== tokenData.user_id) {
      // create formdata in the accepted formate
      cartData[0].user_id = tokenData.user_id;
      cartData[0].items = cartData[0].cart_items;
      delete cartData[0].cart_items;
      getUserCartItem(tokenData.user_id)
        .then((response: any) => {
          console.log(response);
          
          dispatch(
            cartAction.setByValue({ value: response[0]?.cart_items?.length })
          );

          // if cart list user id is not equal to 0 then update the cart list else call the Add API

          if (response.data.data.user_id !== 0) {
            updateLoggedInCart(cartData[0].cart_id, cartData[0])
              .then((res: any) => {
                db.cart.put(res.data.data);
              })
              .catch((err) => {
                console.log(err);
              });
          } else {
            addToCart(cartData[0])
              .then((response) => {
                db.cart.put(response.data.data);
              })
              .catch((err) => console.log(err));
          }
        })
        .catch((err: any) => console.log(err));
    } else if (!cartData.length) {
      getUserCartItem(tokenData.user_id)
        .then((res) => {
          dispatch(
            cartAction.setByValue({ value: res.data.data?.cart_items?.length })
          );

          db.cart.put(res.data.data);
        })
        .catch((err) => console.log(err));
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      syncCartItem();
    }
  }, [isAuthenticated]);

  const partnerDataList: any = partnerData;

  return (
    <>
      <SuperSEO
        title={pageSEO.home.title}
        description={pageSEO.home.description}
      />
      <Box sx={{ position: 'relative' }} className="home-container">
        {/* <Header /> */}
        <Box mx={0}>
          <LoggedInUser style={{ marginBottom: 20 }} />
          {/* banner Slider */}
          <PromotedPackages />
          {/* <HomeBannerCarousel 
            { ...options }
            sliderItems={ bannerData.data }
            loading={bannerData.loading}
            mainDivClss='home-banner'
          /> */}
          <Featured />
          <ExpressCurative />
          <ExpressOTC />
          <Box my={3.5} className="position-relative">
            <div
              className={`mb-4 d-flex align-items-center justify-content-between ${
                healthState.data && 'heading-container-pr'
              }`}
            >
              <p className="color_primary-light font-weight_6 home-title-heading">
                All Healthcare Services
              </p>
              <NavLink
                to={'/all-healthcare-services'}
                className="white-space-nowrap"
              >
                View All
              </NavLink>
            </div>
            <Context.Provider
              value={{
                data: {
                  sliderItems: healthState.data,
                  isLoading: healthState.loading,
                  type: 'all-healthcare-services',
                  mainDivClss: healthState.data && 'top-nav',
                },
              }}
            >
              <SpecialitiesCarousel
                {...serviceOptions}
                mainDivClss={!healthState.loading && 'top-nav'}
              />
            </Context.Provider>
            {healthState.isError && (
              <FormHelperText error>{healthState.errorMssg}</FormHelperText>
            )}
          </Box>
          <Box my={3.5} className="position-relative">
            <div
              className={`mb-4 d-flex align-items-center justify-content-between ${
                healthState.data && 'heading-container-pr'
              }`}
            >
              <p className="color_primary-light font-weight_6 home-title-heading">
                Our Specialities
              </p>
              <NavLink to={'/specialities'} className="white-space-nowrap">
                View All
              </NavLink>
            </div>
            <Context.Provider
              value={{
                data: {
                  sliderItems: specialitiesData.data,
                  isLoading: specialitiesData.loading,
                  type: 'categories',
                  mainDivClss: specialitiesData.data && 'top-nav',
                },
              }}
            >
              <SpecialitiesCarousel
                {...serviceOptions}
                mainDivClss={!specialitiesData.loading && 'top-nav'}
                iconContainer="bg_primary-gradient"
              />
            </Context.Provider>
          </Box>
          <PopularPackagesAndProcedures />
          <DoctorsList />
          <OurStory />
          <Box my={3.5} className="position-relative partner-conatiner">
            <div
              className={`mb-4 d-flex align-items-center justify-content-between heading-container-pr`}
            >
              <p className="color_primary-light font-weight_6 mb-4 home-title-heading">
                Partners & Providers
              </p>
            </div>
            <BannerCarousel
              {...partnersOptions}
              sliderItems={partnerDataList}
              mainDivClss={'top-nav'}
            />
          </Box>
          <DownloadApp />
        </Box>
      </Box>
    </>
  );
};

export default Home;
