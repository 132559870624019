import { useContext, useState } from 'react';
import { PromotedPackageDetailsContext } from './PromotedPackageDetailsPage';
import {
  ServiceImageDoctor,
  ServiceImageTab,
  ServiceMenuBeaker,
  ServiceMenuPlus,
} from '../../assets/images';

function About() {
  const { packageData } = useContext(PromotedPackageDetailsContext);

  const [selectedServiceIndex, setSelectedServiceIndex] = useState<number>();
  const [toggleService, setToggleService] = useState<boolean>(false);

  const facility = packageData?.facilities?.[0];

  const selectService = (index: number) => {
    if(index !== selectedServiceIndex){
      setToggleService(true);
    }else {
      setToggleService(!toggleService);
    }
    setSelectedServiceIndex(index);
  };

  return (
    <div className="promoted-package-about-section">
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginBottom: '20px',
        }}
      >
        {facility?.menu?.length > 0 ? (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div className="promoted-package-about-title">
              What’s In this Package?
            </div>
            {facility?.menu?.length &&
              facility?.menu?.map((service: any, index: number) => {
                return (
                  <div key={index} className="package-items-container">
                    <div>
                      <img
                        className="package-item-image"
                        src={ServiceMenuBeaker}
                        alt=""
                      />
                    </div>
                    <div
                      onClick={() => {
                        selectService(index);
                      }}
                      style={{
                        display: 'flex',
                        cursor: 'pointer',
                        width: '100%',
                      }}
                    >
                      <div className="package-item-details">
                        <div className="package-item-title">
                          {service?.market}
                        </div>
                        {selectedServiceIndex === index && toggleService ? (
                          <div
                            style={{ display: 'flex', flexDirection: 'column' }}
                          >
                            {service?.sample ? (
                              <div
                                style={{
                                  display: 'flex',
                                  width: '100%',
                                  padding: '10px 0 0',
                                }}
                              >
                                <div className="menu-service-title">
                                  Sample:{' '}
                                </div>
                                <div className="menu-service-value">
                                  {service?.sample}
                                </div>
                              </div>
                            ) : null}
                            {service?.requester ? (
                              <div
                                style={{
                                  display: 'flex',
                                  width: '100%',
                                  padding: '10px 0 0',
                                }}
                              >
                                <div className="menu-service-title">
                                  Requester:{' '}
                                </div>
                                <div className="menu-service-value">
                                  {service?.requester}
                                </div>
                              </div>
                            ) : null}
                            {service?.collection ? (
                              <div
                                style={{
                                  display: 'flex',
                                  width: '100%',
                                  padding: '10px 0 0',
                                }}
                              >
                                <div className="menu-service-title">
                                  Collection:{' '}
                                </div>
                                <div className="menu-service-value">
                                  {service?.collection}
                                </div>
                              </div>
                            ) : null}
                            {service?.preparation ? (
                              <div
                                style={{
                                  display: 'flex',
                                  width: '100%',
                                  padding: '10px 0 0',
                                }}
                              >
                                <div className="menu-service-title">
                                  Preparation:{' '}
                                </div>
                                <div className="menu-service-value">
                                  {service?.preparation}
                                </div>
                              </div>
                            ) : null}
                            {service?.tat ? (
                              <div
                                style={{
                                  display: 'flex',
                                  width: '100%',
                                  padding: '10px 0 0',
                                }}
                              >
                                <div className="menu-service-title">TAT: </div>
                                <div className="menu-service-value">
                                  {service?.tat}
                                </div>
                              </div>
                            ) : null}
                          </div>
                        ) : null}
                      </div>
                      {selectedServiceIndex === index && toggleService ? (
                        <div className="package-item-arrow-section">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            style={{ width: '20px', height: '20px' }}
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M4.5 15.75l7.5-7.5 7.5 7.5"
                            />
                          </svg>
                        </div>
                      ) : (
                        <div className="package-item-arrow-section">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            style={{ width: '20px', height: '20px' }}
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                            />
                          </svg>
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
          </div>
        ) : null}

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            padding: '10px 0',
            borderBottom: '1px solid #d9d9d9',
          }}
        >
          <div className="promoted-package-about-title">Why Book with Us?</div>
          <div className="package-items-container-two">
            <div>
              <img
                className="package-item-image"
                src={ServiceMenuPlus}
                alt=""
              />
            </div>
            <div className="package-item-details">
              <div className="package-item-title">
                Home sample collection for FREE
              </div>
              <div className="package-item-long-description">
                A certified professional will collect your sample from your
                preferred location
              </div>
            </div>
          </div>
          <div className="package-items-container-two">
            <div>
              <img
                className="package-item-image"
                src={ServiceImageTab}
                alt=""
              />
            </div>
            <div className="package-item-details">
              <div className="package-item-title">
                Get digital report within 2 days
              </div>
              <div className="package-item-long-description">
                Our labs ensure turn-around-time of 48 hours from specimen
                pickup
              </div>
            </div>
          </div>
          <div className="package-items-container-two">
            <div>
              <img
                className="package-item-image"
                src={ServiceImageDoctor}
                alt=""
              />
            </div>
            <div className="package-item-details">
              <div className="package-item-title">
                Valuable doctor's consultation
              </div>
              <div className="package-item-long-description">
                Get your online reports and review them with one of our doctors
              </div>
            </div>
          </div>
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            padding: '10px 0',
            borderBottom: '1px solid #d9d9d9',
          }}
        >
          <div className="promoted-package-about-title">How it works?</div>
          <div className="package-items-container-two">
            <div>
              <img
                className="package-item-image"
                src={ServiceMenuPlus}
                alt=""
              />
            </div>
            <div className="package-item-details">
              <div className="package-item-long-description">
                Pick a package that suits your healthcare needs and seamlessly
                book a home sample collection
              </div>
            </div>
          </div>
          <div className="package-items-container-two">
            <div>
              <img
                className="package-item-image"
                src={ServiceImageDoctor}
                alt=""
              />
            </div>
            <div className="package-item-details">
              <div className="package-item-long-description">
                We will send a certified professional to your place to assist
                you with the sample collection
              </div>
            </div>
          </div>
          <div className="package-items-container-two">
            <div>
              <img
                className="package-item-image"
                src={ServiceImageTab}
                alt=""
              />
            </div>
            <div className="package-item-details">
              <div className="package-item-long-description">
                After your sample collection, you can access your reports within
                your account on our mobile application. We will also email you
                the reports
              </div>
            </div>
          </div>
          <div className="package-items-container-two">
            <div>
              <img
                className="package-item-image"
                src={ServiceImageDoctor}
                alt=""
              />
            </div>
            <div className="package-item-details">
              <div className="package-item-long-description">
                Schedule an Online consultation with a doctor to understand your
                results better and get medical advice for free
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
