import { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { NavLink } from 'react-router-dom';
import {
  Configure,
  InstantSearch,
  useHits,
} from 'react-instantsearch-hooks-web';
import { PromotedPackageCarousel } from '../../components/commons/Carousel';
import { CalendarLeft, CalendarRight } from '../../assets/images';
import { environment, algolia } from '../../env';
import algoliasearch from 'algoliasearch';
import Context from '../../context';
import { paths } from '../../urlPaths';
import LoadingCard from '../../components/commons/LoadingCard';
import { BottomDrawer } from '../../components';
import SpecialitiesBooking from '../Specialities/SpecialitiesBooking';
import { useSelector } from 'react-redux';
import { defaultRequestLimit } from '../../utils/constants';

function PromotedPackages() {
  const [loadingIndicator, setLoadingIndicator] = useState(true);

  const searchClient = algoliasearch(algolia.appId, algolia.apiKey);

  const facetFilters = [
    [
      'item_details.item_type:Services',
      'item_details.item_type:Packages',
      'item_details.item_type:Products',
    ],
    'is_promoted: true',
  ];

  const handleLoading = () => {
    setTimeout(() => {
      setLoadingIndicator(false);
    }, 1000);
  };

  useEffect(() => {
    handleLoading();
  }, []);

  return (
    <>
      <Box my={0.5} className="position-relative">
        <div
          className={`mb-4 d-flex align-items-center justify-content-between`}
        >
          <p className="color_primary-light font-weight_6 home-title-heading">
            Promoted Health Packages
          </p>
          <NavLink
            to={`/${paths.promotedPackages}`}
            className="white-space-nowrap"
          >
            View All
          </NavLink>
        </div>
        {loadingIndicator && <LoadingCard />}
        <InstantSearch
          searchClient={searchClient}
          indexName={environment.algoliaIndex || 'product_index_newest'}
        >
          <Configure
            hitsPerPage={defaultRequestLimit}
            facetFilters={facetFilters}
          />
          <CarouselWrap />
        </InstantSearch>
        {/* {
          packageData.isError && ( <FormHelperText error>{packageData.errorMssg}</FormHelperText> )
        } */}
      </Box>
    </>
  );
}

function CarouselWrap() {
  const [openBooking, setOpenBooking] = useState<boolean>(false);
  const [selectedPackage, setSelectedPackage] = useState<string>('');
  const [selectedItem, setSelectedItem] = useState<any>();

  const isAuthenticated = useSelector(
    (state: any) => state.auth.isAuthenticated
  );

  let { hits, results } = useHits();

  const bookingToggle = () => {
    setOpenBooking(!openBooking);
  };

  const popularPackOptions: object = {
    nav: true,
    dots: false,
    loop: true,
    margin: 15,
    autoplay: false,
    center: false,
    navText: [
      `<img src=${CalendarLeft} className='rotate' alt="" />`,
      `<img src=${CalendarRight} alt="" />`,
    ],
    responsive: {
      0: { items: 1, nav: false },
      667: { items: 2, nav: false },
      768: { items: 3.2 },
      1300: { items: 3.2 },
      1700: { items: 3.2 },
    },
  };

  return (
    <>
      <PromotedPackageCarousel
        {...popularPackOptions}
        sliderItems={hits}
        isLoading={results!.processingTimeMS <= 0}
        mainDivClss={results!.processingTimeMS <= 0 ? '' : 'side-controls'}
        drawerOpen={(e: any) => {
          setOpenBooking(true);
          setSelectedPackage(e);
        }}
        setItem={(e: any) => {
          setSelectedItem(e);
        }}
      />

      {/* <Context.Provider
        value={{
          data: {
            state: openBooking,
            drawerToggle: bookingToggle,
            packageId: selectedPackage,
            item: selectedItem,
          },
        }}
      >
        <PromotedPackageBooking state={openBooking} click={bookingToggle} />
      </Context.Provider> */}

      {openBooking && (
        <Context.Provider
          value={{
            data: {
              state: openBooking,
              click: bookingToggle,
              info: { ...selectedItem },
              authenticated: isAuthenticated,
            },
          }}
        >
          <BottomDrawer state={openBooking} click={bookingToggle}>
            <SpecialitiesBooking />
          </BottomDrawer>
        </Context.Provider>
      )}
    </>
  );
}

export default PromotedPackages;
