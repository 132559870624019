import { useContext } from 'react';
import { Grid } from '@mui/material';
import { PlaceholderImg } from '../../assets/images';
import { Rating } from '@mui/material';
import '../../assets/styles/promoted-packages.scss';
import InfoTabs from './InfoTabs';
import BookingDetails from './BookingDetails';
import { PromotedPackageDetailsContext } from './PromotedPackageDetailsPage';
import { _currencyFormatter } from '../../utils/Index';

function PromotedPackageDetails() {
  const { packageData, availableAppointmentTypes } = useContext(
    PromotedPackageDetailsContext
  );

  const defaultRating = 3;
   
  return (
    <>
      <Grid container style={{ marginBottom: '50px' }}>
        <Grid item md={9} style={{ flexShrink: 1 }}>
          <Grid item md={12} style={{ flexShrink: 1 }}>
            <div className="package-details-section">
              <div>
                <img
                  className="package-image pt-4"
                  src={packageData?.image_url || PlaceholderImg}
                  alt="Promoted package"
                />
              </div>
              <div className="package-info">
                <div className="package-title">
                  {packageData?.name || packageData?.description}
                </div>
                <div className="package-description-title">
                  {packageData?.facilities?.[0]?.facility?.name}
                </div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: '5px',
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    style={{ width: '20px', height: '20px' }}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"
                    />
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"
                    />
                  </svg>
                  <span style={{ marginLeft: '5px', color: "#5e5873" }}>
                    {packageData?.facilities?.[0]?.facility?.location}
                  </span>
                </div>
                <div
                  style={{
                    marginBottom: '15px',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <div
                    style={{
                      color: '#5E5873',
                      fontWeight: '600',
                      opacity: 0.7,
                    }}
                  >
                    Ponea Ratings
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Rating
                      name="read-only"
                      value={
                        packageData?.facilities?.[0]?.facility?.rast ||
                        defaultRating
                      }
                      precision={0.1}
                      style={{ color: '#FF3C47' }}
                      readOnly
                    />
                    <span style={{ marginLeft: '5px' }}>
                      {packageData?.facilities?.[0]?.facility?.rast ||
                        defaultRating}{' '}
                      Star
                    </span>
                  </div>
                </div>

                {packageData?.facilities?.[0]?.facility?.customer_rating ? (
                  <div
                    style={{
                      marginBottom: '15px',
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <div
                      style={{
                        color: '#5E5873',
                        fontWeight: '600',
                        opacity: 0.7,
                      }}
                    >
                      Patient Ratings
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Rating
                        name="read-only"
                        value={
                          packageData?.facilities?.[0]?.facility
                            ?.customer_rating
                        }
                        precision={0.1}
                        style={{ color: '#FF3C47' }}
                        readOnly
                      />
                      <span style={{ marginLeft: '5px' }}>
                        {
                          packageData?.facilities?.[0]?.facility
                            ?.customer_rating
                        }{' '}
                        Star
                      </span>
                    </div>
                  </div>
                ) : null}
                <div className="package-description">{packageData?.notes}</div>
              </div>
            </div>
          </Grid>
          <Grid container>
            <Grid item md={3} style={{ flexShrink: 1 }}>
              <div
                style={{
                  marginBottom: '30px',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                {packageData?.facilities?.[0]?.facility_visit ? (
                  <div className="appointment-types">
                    <span className="appointment-type">Facility Visit</span>
                    <span className="appointment-price">
                      {_currencyFormatter(
                        packageData?.facilities?.[0]?.facility_visit
                      )}
                    </span>
                  </div>
                ) : null}
                {packageData?.facilities?.[0]?.home_visit ? (
                  <div className="appointment-types">
                    <span className="appointment-type">Home Visit</span>
                    <span className="appointment-price">
                      {_currencyFormatter(
                        packageData?.facilities?.[0]?.home_visit
                      )}
                    </span>
                  </div>
                ) : null}
                {packageData?.facilities?.[0]?.tele_consult ? (
                  <div className="appointment-types">
                    <span className="appointment-type">Teleconsultation</span>
                    <span className="appointment-price">
                      {_currencyFormatter(
                        packageData?.facilities?.[0]?.tele_consult
                      )}
                    </span>
                  </div>
                ) : null}
              </div>
            </Grid>
            <Grid item md={8} style={{ flexShrink: 1, width: "100%" }}>
              <div className='info-tabs-section' style={{ marginBottom: '20px' }}>
                <InfoTabs />
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={3} style={{ flexShrink: 1, width: '100%' }}>
          <div className="booking-section">
            <div className="booking-title">Book Package</div>
            <BookingDetails
              availableAppointmentTypes={availableAppointmentTypes}
              packageData={packageData}
            />
          </div>
          <div className="guidance-section">
            <div className="guidance-upper-section">
              <div className="guidance-text-section">
                <p className="guidance-upper-text">
                  Need help booking this Doctor?
                </p>
                <p className="guidance-lower-text">
                  Our experts are here to help
                </p>
              </div>
              <div className="guidance-image-section">
                <img className="guidance-image" src={PlaceholderImg} alt="" />
              </div>
            </div>
            <div className="guidance-lower-section">
              <button className="get-guidance-btn disabled-btn-css">Get Guidance</button>
            </div>
          </div>
        </Grid>
      </Grid>
    </>
  );
}

export default PromotedPackageDetails;
