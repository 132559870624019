import { rejects } from "assert";
import axios from "axios";
import { resolve } from "path";
import { environment } from "../../env";
import { _conciergeAuthToken } from "./_authToken"

type cancelFormData = {
  payment_method: number,
  amount: number,
  currency: string,
  callback_url: string
}

export const _cancelBookingOrder = async(order_id: string, formData: cancelFormData): Promise<any> => {
  try {
    const token: Record<string, any> | any = await _conciergeAuthToken();
    return new Promise((resolve, reject) => {
      axios.delete(`${environment.concierge}/api/v1/order/${order_id}`, {
        headers: {
          authorization: `Bearer ${token.access_token}`,
          user: token.client.id
        },
        data: formData
      }).then(res => resolve(res)).catch(err => reject(err))
    })
  }catch(err) {
    return err
  }
}

export const _detailBookingOrder = async(order_id: string, formData?: cancelFormData): Promise<any> => {
  try {
    const token: Record<string, any> | any = await _conciergeAuthToken();
    return new Promise((resolve, reject) => {
      axios.get(`${environment.concierge}/api/v1/order/${order_id}`, {
        headers: {
          authorization: `Bearer ${token.access_token}`,
        },
      }).then(res => resolve(res)).catch(err => reject(err))
    })
  }catch(err) {
    return err
  }
}

type analyticsType = {
  item_id: string,
  item_type: string
}
export const _clickAnalyticsUpdate = async(data: analyticsType): Promise<any> => {
  try {
    const formData = {
      item_id: data.item_id,
      item_type: data.item_type
    };
    return new Promise((resolve, reject) => {
      axios.post(`${environment.concierge}/api/v1/analytics/click-through`, formData).then(res => resolve(res)).catch(err => reject(err))
    })
  } catch (error) {
    return error
  }
}

export const _sendOTP = async( prefix: string, mobile_number: string ): Promise<any> => {
  try {
    const otpToken: any = await _conciergeAuthToken();
    return new Promise((resolve, reject) => {
      const formData = {
        prefix: String(prefix),
        number: String(mobile_number),
        validity: 10
      }
      axios.post(`${environment.concierge}/api/common/otp/request`, formData, {
        headers: {
          Authorization: `Bearer ${otpToken.access_token}`
        } 
      }).then((response) => resolve(response)).catch((error) => reject(error));
    })
  } catch (error) {
    return error
  }
}

type otpFormType = {
  sessionId: string, 
  code: string, 
  prefix: string, 
  number: string
}
export  const _validateOTP = async({sessionId, code, prefix, number}: otpFormType) => {
  try {
    const otpToken: any = await _conciergeAuthToken();
    return new Promise((resolve, reject) => {
      const formData = {
        sessionId,
        code,
        prefix,
        number
      }

      axios.post(`${environment.concierge}/api/common/otp/validate`, formData, {
        headers: {
          Authorization: `Bearer ${otpToken.access_token}`
        } 
      }) .then((res) => resolve(res.data)).catch((err) => reject(err));
    });
  } catch (error) {
    return error
  }
}

export const _currentAppVer = async(): Promise<any> => {
  try {
    const otpToken: any = await _conciergeAuthToken();
    return new Promise((resolve, reject) => {
      axios.get(`${environment.concierge}/api/v1/app/version/market_place_2`, {
        headers: {
          authorization: `Bearer ${otpToken.access_token}`
        }
      })
      .then(res => resolve(res.data))
      .catch(err => reject(err))
    })
  } catch (error) {
    console.log(error)
  }
}