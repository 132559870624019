import { Box, Button, Grid } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { ProductDetailsPlaceHolder } from "../../assets/images";
import { PageTitle, BottomDrawer } from "../../components";
import { environment } from "../../env";
import { _currencyFormatter } from "../../utils/Index";
import { conciergeAuth } from "../../utils/tokens";
import BookingInfo from "./BookingInfo";
import CancelBooking from "./CancelBooking";

const BookingCard = (props: any) => {
  const [openDetails, setOpenDetails] = useState<boolean>(false);

  const [openModal, setOpenModal] = useState<boolean>(false);
  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);

  const handleViewDetails = (): any => {
    const body = document.querySelector('body');
    if(!openDetails) {
      body?.classList.add('active-backdrop')
    }else {
      body?.classList.remove('active-backdrop')
    }
    setOpenDetails(!openDetails)
  };

  return (
    <>
      <div className="booking-card d-flex">
        <div className="mr-3 img-info d-flex flex-shrink-0">
          <p className="date-tag">{props.date}</p>
          <img src={props.thumb || ProductDetailsPlaceHolder} alt="" className="mt-auto img-fluid" />
        </div>
        <div className="d-flex flex-column flex-grow-1">
          <p className="fs-16 font-weight_5">{props?.patient?.name}</p>
          <p>Booking id: {props?.code}</p>
          <p>Status: {props.status?.name}</p>
          <span className="fs-12">{props.service}</span>
          <span className="fs-10 mt-1">Amount:</span>
          <p className="fs-16 font-weight_6 color_primary">{ _currencyFormatter(props.amount) }</p>
          <div className="btn-group d-flex flex-wrap">
            {
              props.status.name !== 'Completed' && (
                <Button variant="contained" disableElevation className="btn_primary mr-1 mt-2 flex-grow-1" disabled={props.status.name === 'Cancelled'} onClick={handleOpen}> {props.status.name === 'Cancelled' ? 'Cancelled' : 'Cancel'}</Button>
              )
            }
            <Button variant="outlined" className="mr-1 mt-2 flex-grow-1 color_primary" onClick={handleViewDetails}>View Details</Button>
          </div>
        </div>
      </div>
      {
        openDetails && (
          <BottomDrawer  state={openDetails} click={handleViewDetails} >
            <BookingInfo {...props}  />
          </BottomDrawer>
        )
      }
      {
        openModal && (
          <CancelBooking
            open={openModal}
            onCloseModal={handleClose}
            setBookingId={props.setBookingId}
            {...props}
          />
        )
      }
    </>
  )
}

export default function BookingList(props: any) {
  const [bookingData, setBookingData] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    getBookings();
  }, []);

  const getBookings = async() => {
    const conciergeToken: any = await conciergeAuth();
    const userData: any = localStorage.getItem('userID');
    axios.get(`${environment.concierge}/api/v1/order`, {
      params: {patient_id: JSON.parse(userData)},
      headers: { 
        authorization: `Bearer ${conciergeToken?.access_token}`,
        user: conciergeToken.client.id
      }
    }).then((res: any) => {
      setLoading(false);
      setBookingData(res.data.results)
    }).catch((error) => {
      setLoading(false);
      console.log(error)
    })
  };

  const getCancelId = (bookingId: string) => {
    setBookingData(bookings => bookings.map(booking => {
      if(booking.uuid ===  bookingId) {
        return {
          ...booking,
          status: {
            name: "Cancelled"
          }
        }
      }else {
        return booking
      }
    }));
  }

  return (
    <>
      <PageTitle title={'My Bookings'} />
      <Box className="" my={2}>
        {/* {
          bookingData.length ? <p >Upcoming</p> : null
        } */}
        <Grid container spacing={2}>
          {
            bookingData?.map((booking: any, index: number) => {
              return (
                <Grid item xs={12} sm={6} lg={4} key={index}>
                  <BookingCard {...booking} setBookingId={getCancelId} />
                </Grid>
              )
            })
          }
        </Grid>
        {
          !bookingData.length && loading && <p>Loading...</p>
        }
        {
          !bookingData.length && !loading && <p>No bookings available</p>
        }
      </Box>
    </>
  )
}