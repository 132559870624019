import axios from "axios";
import { format } from "date-fns";
import { ProductPlaceHolder } from "../assets/images";
import { db } from "../db";
import { environment, media } from "../env";
import { getUseProfileData } from "./LoggedInUser";
let wishlistId = '';

export const getWishlist = async () => {
  let wishlist = await db.table('wishlist').toArray();
  return wishlist;
};

export const wishlistHandler = async (data: any, qty = 1, type: string) => {
  try {
    let [ wishlistData, loggedInUser] = await Promise.all([
      getWishlist(),
      getUseProfileData()
    ]);
    loggedInUser = {};
    wishlistId = wishlistData?.[0]?.wishlist_id;
    let itemType = type;

    const formData = {
      // loggedInUser ? loggedInUser.user_id : 
      user_id: '0',
      uuid: "0",
      wishlist_id: wishlistId,
      transaction_fee: 0,
      transport_fee: 0,
      items: [{
        item_id: data.id,
        // wishlist_item_id: data.id,
        item_name: data.name,
        item_price: data.rates?.facility_visit,
        item_quantity: qty,
        location: data.provider_details?.location || null,
        appointment_type: data.formData?.appointment_type_id,
        preferred_date: "",
        type: itemType,
        image: data.images?.main || ProductPlaceHolder
      }],
      currency: "KES"
    };

    if(type === 'doctor') {
      formData.items = [{
        item_id: data.id,
        // wishlist_item_id: data.id,
        item_name: data.name,
        item_price: data.rates?.facility_visit || data.rates?.home_visit || data.rates?.teleconsult || data.rates?.emergency_rate ,
        item_quantity: qty,
        location: data.provider_details?.location,
        appointment_type: data.formData?.appointment_type_id,
        preferred_date: `${data?.formData?.appointment_date || format(new Date(), 'yyyy-MM-dd HH:mm') }${data?.formData?.time_slot || ''}:00`,
        type: 'doctor',
        image: data.images?.main
      }]
    }

    if(!wishlistId) {
      return new Promise((resolve, reject) => {
        axios.post(`${environment.cart}/wishlist`, formData).then((response) => {
          db.wishlist.add(response.data.data);
          resolve(response);
        }).catch((error: any) => {
          reject(error)
        })
      })
    }else {
      // formData.cart_id = cartId;
      return new Promise((resolve, reject) => {
        formData.items.push(...wishlistData[0].wishlist_items);
        axios.put(`${environment.cart}/wishlist/${wishlistId}`, formData).then((response) => {
          // wishlistData[0] = response.data.data;
          db.wishlist.put(response.data.data);
          resolve(response);
        }).catch((error: any) => {
          reject(error)
        })
      })
    }
  } catch (error) {
    console.log(error)
  }
};

export const removeItemFromWishlist = async (wishlistItemId: string) => {
  let [ wishlistItem, loggedInUser] = await Promise.all([
    getWishlist(),
    getUseProfileData()
  ]);

  loggedInUser = {};
  let wishlistId = wishlistItem?.[0]?.wishlist_id;

  const filterWishlistItem = wishlistItem[0].wishlist_items.filter((item: any) => {
    return String(item.item_id) === String(wishlistItemId)
  });

  const data = {
    user_id: '0',
    // user_id: '0',
    uuid: "0",
    wishlist_id: wishlistId,
    wishlist_item_id: filterWishlistItem[0]?.wishlist_item_id
  }

  return new Promise((resolve, reject) => {
    if(!filterWishlistItem.length) {
      return reject({
        message: 'Invalid request.'
      });
    };

    axios.delete(`${environment.cart}/wishlist/item/${filterWishlistItem[0]?.wishlist_item_id}`, {params: data})
    .then((response: any) => {
      const filterdItem = wishlistItem[0].wishlist_items.filter((item: any) =>  String(item.item_id) !== String(wishlistItemId));

        wishlistItem[0].wishlist_items = filterdItem;
        db.wishlist.put(wishlistItem[0]);

      resolve({response, wishlistItem: wishlistItem[0]});
    }).catch((error: any) => {
      reject(error);
    })
  })
};


export const checkWishlistItems = async(id: string) => {

  const wishlist = await getWishlist();
  if(!wishlist.length){
    return false;
  }
  const filterItem = wishlist[0].wishlist_items.findIndex((item: Record<string, string | number>) => String(item.item_id) === String(id))

  return filterItem > -1 ? true : false;
}