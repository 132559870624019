import { Routes, Route} from "react-router-dom";
import UnAuthenticated from './guard/UnAuthenticated';
import Authenticated from './guard/Authenticated';
import { VerifyOTP } from "./components";
import { 
  Home,
  Intro,
  Login,
  ForgetPassword,
  Register,
  DoctorDetails,
  DoctorList,
  PageNotFound,
  BookingList,
  Wishlist,
  Setting,
  CartList,
  NotifiactionList,
  Support,
  PrivacyPolicies,
  TermsAndConditons,
  Logout,
  SpecialitiesList,
  SpecialitiesSubList,
  SpecialitiesDetailsCompareList,
  CouponsAndOffers,
  ReferAndEarn,
  AboutPonea,
  ManagementPayment,
  AddCard,
  MyProfile,
  FasterCheckout,
  PrescriptionList,
  UploadPrescription,
  HelthcareCategoriesList,
  HelthcareCategoriesDetails,
  PackagesAndProceduresList,
  HealthcareServicesList,
  PackagesAndProceduresDetails,
  SearchResultDetails,
  HealthcareServicesSubList,
  PatientAddress,
  FeaturedList,
  CurativePackagesList,
  WishlistDetails,
  PromotedPackagesList,
  DoctorDetailsPage,
  PromotedPackageDetailsPage,
  ServiceDetailsPage
} from "./screens";
import PaymentTerms from "./screens/Terms/PaymentTerms";
import OTCProductList from "./screens/OTCProduct/List";
import { SelectAuth } from "./screens/Auth";
import { paths } from "./urlPaths";

//This component use for routing component

export default function Routers () {
  return (
    <Routes>
      <Route path="info" element={ <Intro /> }></Route>
      {/* <Route path="login" element={ <UnAuthenticated component={ Login } /> }></Route> */}
      <Route path="forget-password" element={ <UnAuthenticated component={ ForgetPassword } /> }></Route>
      <Route path="register" element={ <UnAuthenticated component={ Register } /> }></Route>
      <Route path="select-auth" element={ <UnAuthenticated component={ SelectAuth } /> }></Route>


      <Route path="verify-otp" element={ <VerifyOTP /> }></Route>
      <Route path="faster-checkout" element={ <FasterCheckout /> }></Route>
      <Route path="/" element={ <Home /> }></Route>
      <Route path="featured" element={ <FeaturedList /> }></Route>
      <Route path="curative-packages" element={ <CurativePackagesList /> }></Route>
      <Route path={paths.promotedPackages} element={ <PromotedPackagesList /> }></Route>
      <Route path="otc-products" element={ <OTCProductList /> }></Route>
      <Route path="doctor-list" element={ <DoctorList /> }></Route>
      <Route path="doctor-details/:docName/:docId" element={ <DoctorDetailsPage /> }></Route>
      
      <Route path="bookings" element={ <Authenticated component={ BookingList } /> }></Route>
      <Route path="wishlist" element={ <Authenticated component={ Wishlist } /> }></Route>
      <Route path="settings" element={ <Setting /> }></Route>
      <Route path="about-ponea" element={ <AboutPonea /> }></Route>
      <Route path="settings/manage-payment" element={ <ManagementPayment /> }></Route>
      <Route path="settings/coupons-and-offers" element={ <CouponsAndOffers /> }></Route>
      <Route path="settings/refer-and-earn" element={ <ReferAndEarn /> }></Route>
      <Route path="settings/add-card" element={ <AddCard /> }></Route>
      <Route path="settings/patient-address" element={ <Authenticated component={ PatientAddress } /> }></Route>

      <Route path="my-profile" element={ <Authenticated component={ MyProfile } /> }></Route>
      <Route path="cart" element={ <CartList /> }></Route>
      <Route path="notifications" element={ <NotifiactionList /> }></Route>
      <Route path="support" element={ <Support /> }></Route>
      <Route path="privacy-policies" element={ <PrivacyPolicies /> }></Route>
      <Route path="terms-and-conditions" element={ <TermsAndConditons /> }></Route>
      <Route path="payment-methods-cancellations-and-refunds-policy" element={ <PaymentTerms /> }></Route>
      <Route path="specialities" element={ <SpecialitiesList /> }></Route>
      <Route path="categories/:specialitiesItems" element={ <SpecialitiesSubList /> }></Route>
      <Route path="compare/:SpecialitiesService" element={ <SpecialitiesDetailsCompareList /> }></Route>
      <Route path="prescription" element={ <PrescriptionList /> }></Route>
      <Route path="prescription/:prescription_type" element={ <UploadPrescription /> }></Route>
      <Route path="products" element={ <HelthcareCategoriesList /> }></Route>
      <Route path="products/:healthcare_type_id" element={ <HelthcareCategoriesDetails /> }></Route>

      <Route path={paths.packageDetails} element={ <PromotedPackageDetailsPage /> }></Route>
      <Route path={paths.serviceDetails} element={ <ServiceDetailsPage /> }></Route>

      <Route path="popular-packages-and-proceduresList" element={ <PackagesAndProceduresList /> }></Route>
      <Route path="popular-packages-and-proceduresList/:package_id" element={ <PackagesAndProceduresDetails /> }></Route>

      <Route path="all-healthcare-services" element={ <HealthcareServicesList /> }></Route>
      <Route path="all-healthcare-services/:healthcare_id" element={ <HealthcareServicesSubList /> }></Route>
      <Route path="search/:searchId" element={<SearchResultDetails />}></Route>
      <Route path="search" element={<SearchResultDetails />}></Route>
      <Route path="details/:itemName/:itemId" element={<WishlistDetails />}></Route>

      <Route path="logout" element={ <Logout /> }></Route>
      <Route path="*" element={<PageNotFound />}></Route>
    </Routes>
  )
}