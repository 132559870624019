import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Box, FormControl, FormHelperText, InputAdornment, InputLabel, OutlinedInput } from "@mui/material";
import axios from "axios";
import { useDispatch } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { NavLink, useNavigate } from "react-router-dom";
import { Logo } from "../../assets/images";
import { ButtonDefault, MyIconButton } from "../../components";
import { environment } from "../../env";
import { Regex } from "../../utils/Regex";
import { keyclockAccessToken, patientAuth } from "../../utils/tokens";
import { getUseProfileData, getUserId } from "../../utils/LoggedInUser";
import { db } from "../../db";
import { authAction } from "../../store";

//This component use for set new password

export const login = {
  client_secret: process.env.REACT_APP_login_client_secret,
  client_id: process.env.REACT_APP_login_client_id,
  grant_type: process.env.REACT_APP_login_grant_type,
  scope: process.env.REACT_APP_login_scope,
}

export default function NewPassword(this: any, props: any) {
  const navigator = useNavigate();
  const [userData, setUserData] = useState<any>();
  const [keyclockData, setKeyclockData] = useState<any>();
  const dispatch = useDispatch();
  const [errRes, setErrorRes] = useState<any>('');

  const [values, setValues] = useState({
    password: false,
    confirm_password: false,
  });

  const {register, handleSubmit, formState: {errors}, watch} = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange'
  })

  const password = useRef({});
  password.current = watch("password", "");

//use for show password 

  const handleClickShowPassword = (type: any, ev: any) => {
    if(type === 'password') {
      setValues({
        ...values,
        password: !values.password,
      });
    }else if(type === 'confirm_password') {
      setValues({
        ...values,
        confirm_password: !values.confirm_password,
      });
    }
  };

  const [loading, setLoading] = useState<boolean>(false)
  const validatePass = async (data: any) => {
    setLoading(true);
    const token: any = await keyclockAccessToken();

    const formData = {
      "type": "password",
      "value": data.password,
      "temporary": false
    }

    //change password matter & redirect
    axios.put(`${environment.keyclock}/auth/admin/realms/UAT/users/${keyclockData.data[0].id}/reset-password`, formData, {
      headers: {
        authorization: `Bearer ${token.data.access_token}`
      }
    }).then((res) => {
      setLoading(false)
      console.log(res)
      props.click();
      
      //Attempt Login
      const formData = {
        username: keyclockData.data[0].email,
        password: data.password,
        // Constent keys are in env file
        ...login,
      }
      loggedInUser(formData)
      return
    }).catch(err => {
      console.log(err);
      setLoading(false)
    })
    return
  };

  const loggedInUser = (formData: any) => {
    const config = {
      headers: {
        "content-type": "application/x-www-form-urlencoded"
      }
    };

    setLoading(true);

    axios.post(
      `${environment.keyclock}/auth/realms/UAT/protocol/openid-connect/token`, 
      new URLSearchParams({ ...formData }),
      config
    )
    .then((response: any) => {
      // to set the userId into localStorage
      getUserId(formData.username).then(() => {
        setLoading(false);
        dispatch(authAction.login());
        // Adding keyClock id into localStorage
        db.login.add({...response.data}).then(async () => {
          const userData = await getUseProfileData();
          localStorage.setItem('shop-access', JSON.stringify(userData.user_id));
        });
        navigator('/');
      }).catch((err: any) => {
        setLoading(false);
        console.log(err)
        setErrorRes({
          message: err.response?.data?.message || err?.data?.message  || err?.response?.data?.error_description || 'Something went wrong, please try again.'
        })
      })
    })
    .catch((err) => {
      setLoading(false);
      if(err.response) {
        setErrorRes({
          message: err.response.data.message || err.response.data.error_description
        })
      }else {
        setErrorRes({
          message: err.message
        })
      }
    })
  }

  useEffect(() => {
    keyclockId()
  }, []);

//get existing patient details 

  const patientCheck = async () => {
    const token: any = await patientAuth();
    return new Promise((resolve, reject) => {
      axios.get(`${environment.patient}/patient`, {
        params: {
          mobile_number: props.data.country_prefix+props.data.mobile_number
        },
        headers: {
          authorization: 'Bearer ' + token?.access_token
        }
      }).then((res) => {
        resolve(res.data.returned_resultset[0])
        setUserData(res.data.returned_resultset[0])
      }).catch((err) => reject(err))
    })
  }
//get existing user

  const keyclockId = async() => {
    const patientInfo: any = await patientCheck();
    const token: any = await keyclockAccessToken();

    axios.get(`${environment.keyclock}/auth/admin/realms/UAT/users`, {
      params: {
        email: patientInfo.email
      },
      headers: {
        authorization: `Bearer ${token?.data?.access_token}`
      },
    }).then((response: any) => {
      console.log(response)
      setKeyclockData(response)
    }).catch((err) => { })
  }

  return (
    <>
      <div className="py-6 px-3 text-center">
        <NavLink to="/">
          <img src={Logo} className='site-logo' alt="" />
        </NavLink>
      </div>
      <p className='bg_primary-gradient py-3 px-2 text-center color_white fs-18 font-weight_7'>Update Password</p>
      <div className="pt-4 pb-6 px-3 text-center">
      <Box component="form"
        className="get-guide-form-container"
        onSubmit={handleSubmit(validatePass)}
        sx={{
          '& .MuiTextField-root': { my: 1.4, width: '100%' },
          '& .MuiFormControl-root': { my: 1.4, width: '100%' },
        }}
        autoComplete="off"
      >
        <FormControl variant="outlined">
          <InputLabel htmlFor="new-password">New Password</InputLabel>
          <OutlinedInput
            id="new-password"
            type={values.password ? 'text' : 'password'}
            {...register("password", 
              { required: "Password required",
                pattern: {
                  value: Regex.password,
                  message: "Minimum eight characters, at least one letter, one number and one special character"
                }
              })
            }
            error={errors.passowrd ? true : false}
            endAdornment={
              <InputAdornment position="end">
                <MyIconButton
                  click={handleClickShowPassword.bind(this, 'password')} 
                  icon={values.password ? <VisibilityOff /> : <Visibility />}
                />
              </InputAdornment>
            }
            label="New Password"
            placeholder="Enter Your New Password"
          />
          {
            errors.password && (
              <FormHelperText error>
                {errors.password && errors.password['message']}
              </FormHelperText>

            )
          }
        </FormControl>
        <FormControl variant="outlined">
          <InputLabel htmlFor="confirm-password">Confirm Password</InputLabel>
          <OutlinedInput
            id="confirm-password"
            type={values.confirm_password ? 'text' : 'password'}
            {...register(
              'confirm_password', 
              {
                required: 'Confirm Password required',
                validate: (value) => value === password.current || "The passwords do not match"
              })
            }
            error={errors.confirm_password ? true : false}
            endAdornment={
              <InputAdornment position="end">
                <MyIconButton
                  click={handleClickShowPassword.bind(this, 'confirm_password')} 
                  icon={values.confirm_password ? <VisibilityOff /> : <Visibility />}
                />
              </InputAdornment>
            }
            label="Confirm Password"
            placeholder="Enter Your Confirm Password"
          />
          {
            errors.confirm_password && (
              <FormHelperText error>
                {errors.confirm_password && errors.confirm_password['message']}
              </FormHelperText>

            )
          }
        </FormControl>
        <ButtonDefault
          title={'Update Now'}
          loading={props.loading || loading}
          classes='mt-6 my-auto'
          type='submit'
        />
        <ButtonDefault
          title={'Back to home'}
          btnClass={'bg_transparent border_primary w-100'}
          loading={props.loading}
          classes='mt-3 my-auto'
          type='button'
          click={() => {
            navigator('/')
          }}
        />
      </Box>
      </div>
    </>
  )
}
