import React, { useEffect, useState } from 'react'
import { Grid, Button, Skeleton } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { algolia, environment } from '../../env';
import CartHandler from '../../utils/CartHandler';
import LoadingButton from '@mui/lab/LoadingButton';
import { useDispatch } from 'react-redux';
import { cartAction } from '../../store';
import { Configure, InstantSearch, useHits } from 'react-instantsearch-hooks-web';
import algoliasearch from 'algoliasearch';
import AlgoliaFilter from '../../components/commons/AlgoliaFilter';
import { SuperSEO } from 'react-super-seo';
import { pageSEO } from '../../seo';
import { ProductDetailsPlaceHolder } from '../../assets/images';
import { LoaderMain, ResultNotFound } from '../../components';
import { _currencyFormatter } from '../../utils/Index';
import CartQtyUpdate from '../../components/commons/CartQtyUpdate';
import { useSelector } from 'react-redux';
import DesktopFilter from '../Specialities/DesktopFilter';

//This component use for add to cart item

const AddCartBtn = (props: any) => {
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useDispatch();

  const addToCartHandler = () => {
    props.cartCardUpdate(false);
    setLoading(true);
    CartHandler(props, 1, props.item_details.item_endpoint).then((response) => {
      setLoading(false);
      dispatch(cartAction.addCart());
      props.cartCardUpdate(true);
    }).catch((error: any) => {
      console.log(error)
      setLoading(false);
    });
  }

  //bacdrop active

  useEffect(() => {
    let body = document.querySelector('body');

    if(loading){
      body?.classList.add('active-backdrop');
    }else {
      body?.classList.remove('active-backdrop')
    }
  })

  return (
    <>
      <LoadingButton 
        loading={loading ? true : false} 
        variant="contained"
        onClick={addToCartHandler}
        size="small"
        disableElevation
      >
        Add to Cart
      </LoadingButton>
      {
        loading && <LoaderMain state={loading}/>
      }
    </>
  )
}

const Card = (props: any) => {
  let { hits, results } = useHits();
  const { healthcare_type } = useParams() ?? 'products';
  const navigate = useNavigate();

  //render the quantity button

  const [cardLoader, setCardLoader] = useState<boolean>(false);

  const cartUpdatedToggle = useSelector((state: any) => state.cart.cartUpdatedToggle);

  useEffect(() => {
    setCardLoader(!cardLoader)
  }, [cartUpdatedToggle]);

  return (
    <>
      {
        hits?.map((product: any, index: any) => {
          return (
            <Grid item xs={12} lg={6} key={index}>
              <div className='product-card-container'>
                <div className='product-thumb-container flex-shrink-0'>
                <img src={product?.images?.main ? product.images.main : ProductDetailsPlaceHolder} alt="" />
                </div>
                <div className='d-flex align-items-center flex-column flex-md-row flex-grow-1'>
                  <div className='mx-3 flex-grow-1'>
                    <p className='fs-17 font-weight_5 package-heading-clamp' style={{wordBreak: 'break-word'}}>{product?.name}</p>
                    <p className='fs-15 color_black75 mb-1'>{product?.group?.name}</p>
                    <p className='fs-15 color_black75 mt-4'>From</p>
                    <p className='fs-18 font-weight_6 color_primary-light'>{_currencyFormatter(product?.rates?.facility_visit)}</p>
                  </div>
                  <div className='d-flex flex-column mt-2 flex-shrink-0' style={{width: 175}}>
                    <CartQtyUpdate 
                      productID={product.id}
                      isUpdate={cardLoader}
                    >
                      <AddCartBtn {...product} cartCardUpdate={setCardLoader} />
                    </CartQtyUpdate>
                    <Button 
                      size="small"
                      className='ml-0 mt-2 bg_black color_white'
                      variant="contained"
                      disableElevation
                      onClick={() => navigate(`/${healthcare_type ?? 'products'}/${product?.slug}`)}
                    >
                      View Details
                    </Button>
                  </div>
                </div>
              </div>
            </Grid>
          )
        })
      }
      {
        results!.processingTimeMS <= 0  && (
          <Grid item xs={12} lg={6}>
            <div className='product-card-container'>
              <div className='product-thumb-container flex-shrink-0'>
                <Skeleton animation="wave" variant="rectangular" width={'100%'} height={'100%'} />
              </div>
              <div className='d-flex align-items-center flex-column flex-md-row flex-grow-1'>
                <div className='mx-3 flex-grow-1' style={{width: 'calc(100% - 20px)'}}>
                  <p className='fs-19 font-weight_5'><Skeleton animation="wave" height={30} width="85%"  /></p>
                  <p className='fs-16 color_black75 mb-1'><Skeleton animation="wave" height={20} width="75" /></p>
                  <p className='fs-15 color_black75 mt-2'><Skeleton animation="wave" height={20} width="50" /></p>
                  <p className='fs-15 color_black75 mt-2 d-none d-md-block'><Skeleton animation="wave" height={25} width="50"  /></p>
                </div>
                <div className='d-flex flex-column mt-3 flex-shrink-0' style={{width: 200}}>
                  <Skeleton animation="wave" height={40} width="100%" />
                  <Skeleton animation="wave" height={40} width="100%" className='ml-0' />
                </div>
              </div>
            </div>
          </Grid>
        )
      }
    </>
  )
}


export default function HelthcareCategoriesList() {
  const {healthcare_type} = useParams();
  const [searchParam, setSearchParam] = useState<string>('')
  const searchClient = algoliasearch(
    algolia.appId,
    algolia.apiKey
  );

  useEffect(() => {
    if(healthcare_type === 'service') {
      setSearchParam('Services')
    }else  {
      setSearchParam('Products')
    }
  }, []);

  return (
    <>
      <SuperSEO
        title={pageSEO.product.title}
        description={pageSEO.product.description}
      />
      <Grid container spacing={1.5}>
        <InstantSearch
          searchClient={searchClient}
          indexName={environment.algoliaIndex || 'product_index_newest'}
          searchFunction={(helper: any) => {
            helper.setQuery(searchParam).search()
          }}
        >
          <AlgoliaFilter classes="bg_primary mb-6" btnClass="color_white" filterBtnClass="d-xl-none d-block"/>
          <DesktopFilter/>
          <Configure hitsPerPage={500}  distinct={true} restrictSearchableAttributes={["item_details.item_type"]}/>
          <Card />
          <ResultNotFound />
        </InstantSearch>
      </Grid>
    </>
  )
}
