import { useRef } from 'react';
import '../../component.scss';

//Use this componenet for creating custom radio button

const RadioButton = ({type = 'Male', ...props}: any) => {
  const ref = useRef();
  const buttonRef = ref.current && ref.current;

  return(
    <>
      <input type="radio" 
        id={props.id} 
        className={`d-none custom-radio ${props.error && 'error'}`}
        value={props.id}
        name={props.name}
        ref={buttonRef}
        onChange={(e) => props.change(e.target.id)}
      />
      <label htmlFor={props.id} className={props.classes}>{props.title}</label>
    </>
  )
};

export default RadioButton;