import React, { useEffect, useState } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import MyIconButton from './Button/MyIconButton';
import { Button } from '@mui/material';
import { FlagIcon, LeftArrowBlack, Logo } from '../../assets/images';
import MenuDrawer from './Drawer/MenuDrawer';
import '../component.scss';
import { db } from '../../db';
import { useDispatch, useSelector } from 'react-redux';
import { cartAction } from '../../store';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';

//This component use for header on home page

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Header = (props: any) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [openToast, setOpenToast] = useState<boolean>(false);

  const getCart = async () => {
    const data = await db.table('cart').toArray();
    return data;
  };

  getCart().then((res) => {
    dispatch(cartAction.setByValue({ value: res[0]?.cart_items?.length }));
  });

  let loginInfo = useSelector((state: any) => state.auth.isAuthenticated);
  let cartItems = useSelector((state: any) => state.cart.cartItem);
  let cartItemCount = useSelector((state: any) => state.cart.cartItemCount);

  const checkHeaderHeight = () => {
    let headerHeight = document.querySelector('.header-container');
    let breakItem = document.querySelector('.break-item');

    if (headerHeight!.clientHeight > 0)
      breakItem?.setAttribute(
        'style',
        `padding-top: ${headerHeight?.clientHeight}px`
      );
    else breakItem?.setAttribute('style', `padding-top: 40px`);
  };

  const redirectTo = () => {
    navigate(-1);
  };

  const isBackBtn: string = props.redirectTo;

  useEffect(() => {
    checkHeaderHeight();
  }, []);

  const style = {
    fill: '#F86143',
    fontSize: '28px',
  };

  useEffect(() => {
    if (cartItemCount > 0) {
      setOpenToast(true);
    }
  }, [cartItemCount]);

  const closeToast = () => {
    setOpenToast(false);
  };

  return (
    <>
      <header className="header-container mb-md-7">
        <div className="d-flex align-item-center">
          {isBackBtn ? (
            <MyIconButton
              classes="mr-3"
              click={redirectTo}
              imgIcon={LeftArrowBlack}
            />
          ) : (
            <MenuDrawer classes="d-md-none" />
          )}
          <NavLink to={'/'} className="d-none d-md-block">
            <img src={Logo} className="site-logo" alt="" />
          </NavLink>
        </div>
        <div className="d-flex align-items-center">
          <div className="d-flex flex-column flag-container">
            <img src={FlagIcon} alt="" />
            <p>Kenya</p>
          </div>
          <MyIconButton
            click={props.searchClick}
            icon={<SearchOutlinedIcon style={style} />}
          />
          {location.pathname === '/cart' ? (
            ''
          ) : (
            <MyIconButton
              click={props.cartClick}
              count={cartItems}
              icon={<ShoppingCartOutlinedIcon style={style} />}
            />
          )}
          <div className="d-none d-md-block">
            {loginInfo ? (
              <Button
                variant="outlined"
                size="small"
                className="mx-2"
                onClick={() => navigate('/my-profile')}
              >
                {' '}
                my account
              </Button>
            ) : (
              <Button
                variant="outlined"
                size="small"
                className="mx-2"
                onClick={() => navigate('/select-auth')}
              >
                {' '}
                Login
              </Button>
            )}
            <Button variant="outlined" size="small" href="tel:+254111013900">
              +254 111 013 900
            </Button>
          </div>
        </div>
        <div className="border-bottom-md d-none"></div>
      </header>

      <Snackbar
        open={openToast}
        autoHideDuration={6000}
        onClose={closeToast}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert
          onClose={closeToast}
          severity="success"
          sx={{ width: '100%' }}
          style={{ color: 'white' }}
        >
          Item successfully added to cart!!
        </Alert>
      </Snackbar>
    </>
  );
};

export default Header;
