import { Button, Grid } from "@mui/material"
import { useEffect, useState } from "react"
import { RefinementList } from "react-instantsearch-hooks-web"
import FilterListIcon from '@mui/icons-material/FilterList';
import { Filter } from "../../screens";

//This component use for algolia filter

const AlgoliaFilter = ({classes, location, ...props}: any) => {
  const [filterState, setFilterState] = useState<boolean>(false);

  useEffect(() => {
    if(location === 'search') {
      let bodyEl = document.querySelector('body');
      bodyEl?.classList.add('active-backdrop')
    }
  }, []);
  const handleFilter = () => {
    setFilterState(!filterState);
    if(location !== 'search') {
      let bodyEl = document.querySelector('body');

      if(!filterState) {
        bodyEl?.classList.add('active-backdrop')
      }else {
        bodyEl?.classList.remove('active-backdrop')
      }
    }
  } 

  return (
    <>
      <Grid item xs={12} lg={12}> 
        <div className={`d-flex justify-content-between align-items-center ${classes}`}>
          <div className="d-flex align-items-center w-100">
            {props.children}
          </div>
          <div className={props.filterBtnClass}>
            <Button size="small" onClick={handleFilter} startIcon={<FilterListIcon />} className={`filter-btn ${props.btnClass}`} >Filter</Button>
          </div>
        </div>
        <Filter  state={filterState} click={handleFilter}>
          <p className="fs-14 font-weight_5 mb-2 mt-4">Rast score</p>
          <RefinementList attribute='provider_details.rast_score' operator="or" limit={4} showMore />
          <p className="fs-14 font-weight_5 mb-2 mt-4">Type</p>
          <RefinementList attribute='item_details.item_type' operator="or" limit={4} showMore />
          <p className="fs-14 font-weight_5 mb-2 mt-4">Amenities</p>
          <RefinementList attribute='provider_details.filter.amenities' operator="or" limit={4} showMore />
          <p className="fs-14 font-weight_5 mb-2 mt-4">Number of Beds</p>
          <RefinementList attribute='provider_details.filter.no_beds' operator="or" limit={4} showMore />
          <p className="fs-14 font-weight_5 mb-2 mt-4">ICU beds</p>
          <RefinementList attribute='provider_details.filter.icu_bed' operator="or" limit={4} showMore />
          <p className="fs-14 font-weight_5 mb-2 mt-4">Standalone type</p>
          <RefinementList attribute='provider_details.standalone_type' operator="or" limit={4} showMore />
          <p className="fs-14 font-weight_5 mb-2 mt-4">Facility visit</p>
          <RefinementList attribute='rates.facility_visit' operator="or" limit={4} showMore />
          <p className="fs-14 font-weight_5 mb-2 mt-4">Home visit</p>
          <RefinementList attribute='rates.home_visit' operator="or" limit={4} showMore />
          <p className="fs-14 font-weight_5 mb-2 mt-4">Teleconsult</p>
          <RefinementList attribute='rates.teleconsult' operator="or" limit={4} showMore />
          <p className="fs-14 font-weight_5 mb-2 mt-4">Score</p>
          <RefinementList attribute='rating.score' operator="or" limit={4} showMore />
          <p className="fs-14 font-weight_5 mb-2 mt-4">Tourism</p>
          <RefinementList attribute='is_tourism' operator="or" limit={4} showMore />
          <p className="fs-14 font-weight_5 mb-2 mt-4">CDM</p>
          <RefinementList attribute='is_cdm' operator="or" limit={4} showMore />
        </Filter>
      </Grid>
    </>
  )
}

export default AlgoliaFilter;