import React, { useEffect, useState } from 'react'
import { Grid, Skeleton } from '@mui/material'
import axios from 'axios';
import { environment } from '../../env';
import { NavLink } from 'react-router-dom';
import { SuperSEO } from 'react-super-seo';
import { pageSEO } from '../../seo';

const Card = ({isLoading, ...props}: any) => {
  return (
    <>
      <NavLink to={`/categories/${props.name?.replaceAll(' ','-').replaceAll('/','-').toLowerCase()}`} className='popular-package-card' >
        <div>
          {
            isLoading ?
              <Skeleton animation="wave" variant="rectangular" width={'100%'} height={160} />
            :
            <img src={props.image_url} alt="" className='img-fluid' />
          }
        </div>
        <div className='p-3'>
          {
            isLoading ?
            <>
              <Skeleton animation="wave" variant="rectangular" width={'100%'}  className='mb-4' height={20} />
              <Skeleton animation="wave" variant="rectangular" width={'100%'}  className='mb-2' height={10} />
              <Skeleton animation="wave" variant="rectangular" width={'100%'}  className='mb-2' height={10} />
              <Skeleton animation="wave" variant="rectangular" width={'100%'}  className='mb-2' height={10} />
            </>
            :
            <>
              <p className='fs-18 font-weight_6 my-1 package-heading-clamp'>{props.name}</p>
              <p className='fs-12 package-clamp'>{props.notes}</p>
            </>
          }
        </div>
      </NavLink>
    </>
  )
}
export default function PackagesAndProceduresList() {
  const [packageData, setPackageData] = useState<any>({
    loading: true,
    data: new Array(5).fill(''),
    isError: false,
    isCalled: false,
    isData: false
  });

  const getPackages = () => {
    setPackageData({
      loading: true,
      isCalled: true,
      data: new Array(5).fill(''),
    })
    axios.get(environment.service_catalogue + '/package?is_express=true')
      .then((response) => {
        const finalData = response['data']['returned_resultset'].map((item: any) => ({
          ...item,
        }))
        setPackageData({
          loading: false, 
          data: finalData, 
          isCalled: false,
          isData: true
        });
      }).catch((error) => {
        setPackageData({
          loading: false, 
          data: [], 
          isCalled: true, 
          isError: true,
          errorMssg: error.message
        });
      })
  }

  useEffect (() => {
    getPackages()
  }, []);

  return (
    <>
      <SuperSEO
        title={pageSEO.popularPack.title}
        description={pageSEO.popularPack.description}
      />

      <p className='color_primary-light font-weight_5 mb-4 home-title-heading'>Popular Packages & Procedures</p>
      <Grid container spacing={2}>
        {
          packageData.data?.map((item: any, index: any) => {
            return (
              <Grid item xs={12} md={4} lg={3} key={index}>
                <Card {...item} isLoading={packageData.loading} />
              </Grid>
            )
          })
        }
      </Grid>
    </>
  )
}
