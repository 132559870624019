import React from 'react';
import { RefinementList } from 'react-instantsearch-hooks-web';

export default function DesktopFilter () {
  return (
    <div className="desktop-filter filter-container d-none d-xl-block">
      <p className="fs-12 font-weight_5 mb-2 mt-4">Rast score</p>
      <RefinementList attribute='provider_details.rast_score' operator="or" limit={ 4 } showMore />
      <p className="fs-12 font-weight_5 mb-2 mt-4">Type</p>
      <RefinementList attribute='item_details.item_type' operator="or" limit={ 4 } showMore />
      <p className="fs-12 font-weight_5 mb-2 mt-4">Amenities</p>
      <RefinementList attribute='provider_details.filter.amenities' operator="or" limit={ 4 } showMore />
      <p className="fs-12 font-weight_5 mb-2 mt-4">Number of Beds</p>
      <RefinementList attribute='provider_details.filter.no_beds' operator="or" limit={ 4 } showMore />
      <p className="fs-12 font-weight_5 mb-2 mt-4">ICU beds</p>
      <RefinementList attribute='provider_details.filter.icu_bed' operator="or" limit={ 4 } showMore />
      <p className="fs-12 font-weight_5 mb-2 mt-4">Standalone type</p>
      <RefinementList attribute='provider_details.standalone_type' operator="or" limit={ 4 } showMore />
      <p className="fs-12 font-weight_5 mb-2 mt-4">Facility visit</p>
      <RefinementList attribute='rates.facility_visit' operator="or" limit={ 4 } showMore />
      <p className="fs-12 font-weight_5 mb-2 mt-4">Home visit</p>
      <RefinementList attribute='rates.home_visit' operator="or" limit={ 4 } showMore />
      <p className="fs-12 font-weight_5 mb-2 mt-4">Teleconsult</p>
      <RefinementList attribute='rates.teleconsult' operator="or" limit={ 4 } showMore />
      <p className="fs-12 font-weight_5 mb-2 mt-4">Score</p>
      <RefinementList attribute='rating.score' operator="or" limit={ 4 } showMore />
      <p className="fs-12 font-weight_5 mb-2 mt-4">Tourism</p>
      <RefinementList attribute='is_tourism' operator="or" limit={ 4 } showMore />
      <p className="fs-12 font-weight_5 mb-2 mt-4">CDM</p>
      <RefinementList attribute='is_cdm' operator="or" limit={ 4 } showMore />
    </div>
  );
}
