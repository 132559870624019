import { createContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { SuperSEO } from 'react-super-seo';

import { SecondaryLoader } from '../../../components/commons/LoaderMain';
import { environment } from '../../../env';
import {
  appointmentTypesDataArray,
  homeAppointmentName,
  facilityAppointmentName,
  teleconsultAppointmentName,
} from '../../../utils/constants';
import ServiceDetails from './ServiceDetails';

export const ServiceDetailsContext = createContext<any>({});

function ServiceDetailsPage() {
  const { itemId, itemCompareEndpoint, itemEndpoint } = useParams();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [serviceData, setServiceData] = useState<any>(null);
  const [availableAppointmentTypes, setAvailableAppointmentTypes] =
    useState<any>(null);

  useEffect(() => {
    if (itemId && itemEndpoint && itemCompareEndpoint) {
      fetchServiceData();
    }
  }, [itemId, itemEndpoint, itemCompareEndpoint]);

  const fetchServiceData = () => {
    setIsLoading(true);

    setHasError(false);

    let facilityUrl = `${environment.service_catalogue}/${itemEndpoint}/${itemId}`;

    axios
      .get(facilityUrl)
      .then((response) => {
        let facilityObj = response.data.returned_resultset?.[0];

        let responseId = facilityObj?.service_id || facilityObj?.package_id;

        let packageUrl = `${environment.service_catalogue}/${itemCompareEndpoint}/${responseId}`;

        axios
          .get(packageUrl)
          .then((response) => {
            let serviceObj = response.data.returned_resultset;

            if (serviceObj?.facilities?.length > 0) {
              let filteredFacilityArr = serviceObj?.facilities?.filter(
                (facility: any) =>
                  facility?.facility_id === facilityObj?.facility_id
              );

              serviceObj = {
                ...serviceObj,
                facilities: filteredFacilityArr,
                itemEndpoint: itemEndpoint,
              };

              let availableAppointmentTypesArr = appointmentTypesDataArray?.map(
                (item) => {
                  let rate = 0;

                  if (item?.name === facilityAppointmentName) {
                    rate = filteredFacilityArr?.[0]?.facility_visit;
                  } else if (item?.name === homeAppointmentName) {
                    rate = filteredFacilityArr?.[0]?.home_visit;
                  } else if (item?.name === teleconsultAppointmentName) {
                    rate = filteredFacilityArr?.[0]?.tele_consult;
                  }

                  return {
                    ...item,
                    id: String(item?.id),
                    rate,
                  };
                }
              );

              setAvailableAppointmentTypes(availableAppointmentTypesArr);
            }

            setServiceData(serviceObj);
            setIsLoading(false);
          })
          .catch(() => {
            setHasError(true);
            setIsLoading(false);
          });
      })
      .catch(() => {
        setHasError(true);
        setIsLoading(false);
      });
  };

  return (
    <>
      <SuperSEO
        title={serviceData?.name || serviceData?.description}
        description={serviceData?.notes}
      />

      <ServiceDetailsContext.Provider
        value={{
          serviceData,
          availableAppointmentTypes,
          faqs: serviceData?.facilities?.[0]?.faq || serviceData?.faq || [],
        }}
      >
        {isLoading ? <SecondaryLoader /> : <ServiceDetails />}
      </ServiceDetailsContext.Provider>
    </>
  );
}

export default ServiceDetailsPage;
