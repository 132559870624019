import { useContext, useEffect, useState } from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { NavLink, useNavigate } from 'react-router-dom';
import RadioButton from './Input/RadioButton';
import { Skeleton } from '@mui/material';
import { GeneralMedicalIcon } from '../../assets/images';
import { CalendarLeft, CalendarRight } from '../../assets/images';
import Context from '../../context';
import { _currencyFormatter } from '../../utils/Index';
import OTCCurativeCard from './OTCCurativeCard';
import FeaturedCard from './FeaturedCard';
import { _clickAnalyticsUpdate } from '../../utils/API_SERVICE/_concierge';
import PromotedPackageCard from './PromotedPackageCard';

//Using OwlCarousel create reusable carousel

export const DefaultConfig = (props: any) => {
  const [child, setChild] = useState<any>(props.children);

  useEffect(() => {
    setChild(props.children)
  }, [props.children]);

  return (
    <OwlCarousel className={`owl-theme ${props.mainDivClss}`}
      nav={props.nav}
      dots={props.dots}
      center={props.center}
      loop={props.loop}
      margin={props.margin} 
      items={props?.items}
      autoplay={props.autoplay}
      responsive={props.responsive}
      navText={
        [
          `<img src=${CalendarLeft} className='rotate' alt="" />`,
          `<img src=${CalendarRight} alt="" />`
        ]
      }
      mouseDrag={props.mouseDrag}
      touchDrag={props.touchDrag}
      style={props.styles}
    >
      { props.content }
      { props.children }
      {/* { child } */}
    </OwlCarousel>
  )
}

//This component are default carousel

export default function  Carousel(props: any) {
  return (
    <>
      <OwlCarousel className='owl-theme' 
        nav={props.nav}
        dots={props.dots}
        center={props.center}
        loop={props.loop}
        margin={props.margin} 
        items={props?.items}
        autoplay={props.autoplay}
        responsive={props.responsive}
        navText={props.navText}
        mouseDrag={props.mouseDrag}
        touchDrag={props.touchDrag}
        style={props.styles}
      >
        {
          props?.sliderItems?.map((item: any, index: number) => {
            return (
              <div className='item' key={index}>
                {
                  item?.title ?
                    <NavLink to={`/categories/${item?.title.trim().toLowerCase().replaceAll(' ', '-')}`}
                      className='text-center mt-1 d-block fs-12'
                    >
                      <img src={item.icon} alt={item.title} className='specialities-icon' />
                      {item.title}
                    </NavLink>
                    :
                    ''
                }
              </div>
            )
          })
        }
      </OwlCarousel>
    </>
  )
}

//Using for doctor & facility service date select calender carousel

const CalendarCard = ({sliderItems, click, change, ...props}: any) => {
  return (
    <>
      {
        sliderItems?.map((item: any, index: number) => {
          return (
            <div className='item' key={index}>
              <RadioButton
                title={
                  <div className="d-flex flex-column">
                    <p>{item.day}</p>
                    <p className='font-weight_6'>{item.date}</p> 
                  </div>
                } 
                change={() => change(item.value)}
                value={item.value} 
                id={'selectDate_' + ++index} 
                key={'selectDate_' + ++index} 
                name={'select-appointment-date'}
                classes='fs-14 px-1 d-flex align-items-center justify-content-center py-1'
              />
            </div>
          )
        })
      }
    </>
  )
}

//Using for banner carousel

const BannerCard = ({sliderItems, loading, ...props}: any) => {

  return (
    <>
      {
        sliderItems.map((item: any, index: number) => {
          return (
            <div className={`item ${props.mainDivClss === 'home-banner' ? ' bannner-item-container' : '' }`} key={index}>
              {
                !loading ? 
                <img src={item.img ? item.img : item.image_url} className='img-fluid' alt="" />
                :
                <Skeleton
                  animation="wave"
                  variant="rectangular"
                  width={'100%'}
                  height={180}
                  style={{borderRadius: 10}}
                />
              }
            </div>
          )
        })
      }
    </>
  )
}

//Using for home banner carousel

const HomeBannerCard = ({sliderItems, loading, ...props}: any) => {
  const navigation = useNavigate();

  const navigateTo = (item: any) => {
    _clickAnalyticsUpdate({
      item_id: item.id,
      item_type: 'facility-package'
    }).then(res => console.log(res)).catch(err => console.log(err))
    navigation(`/categories/${item.name?.replaceAll(' ', '-')?.toLowerCase()}`, {
      state:{title: item.name}
    })
  }

  return (
    <>
      {
        sliderItems.map((item: any, index: number) => {
          return (
            <button disabled={ loading } onClick={navigateTo.bind('e', item)} className="bannner-item-container w-100 btn cursor-pointer" key={index}>
              {
                !loading ? 
                <img src={item.img ? item.img : item.image_url} className='img-fluid' alt={item.name} />
                :
                <Skeleton
                  animation="wave"
                  variant="rectangular"
                  width={'100%'}
                  height={180}
                  style={{borderRadius: 10}}
                />
              }
            </button>
          )
        })
      }
    </>
  )
}

//This component use for specialities card

export const SpecialitiesCard = ({ iconContainer,  ...props}: any) => {
  const { data } = useContext(Context);
  const url = `/${data.type}`;

  return (
    <>
      {
        !data.isLoading ? 
        data.sliderItems?.map((item: any, index: number) => {
          return (
            <div className='item' key={index} onClick={() => {
                const data = {
                  item_id: item.id,
                  item_type: 'facility-package',
                };
                _clickAnalyticsUpdate( data ).then(res => console.log(res)).catch(err => console.log(err));
            }}>
              <NavLink to={data.type === 'all-healthcare-services' ? `${url}/${item?.id}` : `${url}/${item?.title.replaceAll(' ', '-').toLowerCase()}`}
                state={{
                  title: item.title,
                  icon: item.image_url || GeneralMedicalIcon,
                }}
                className='text-center mt-1 d-block fs-12'
              >
                {
                  data.sliderItems ?
                  <div className={`specialities-icon ${iconContainer}`}>
                    <img src={item.image_url || GeneralMedicalIcon} alt={item.title} />
                  </div>
                  :
                  <Skeleton animation="wave" variant="rectangular" width={'100%'} height={80} />
                }
                <p className="package-heading-clamp">{item.title}</p>
              </NavLink>
            </div>
          )
        })
        :
        data.sliderItems?.map((item: any, index: number) => {
          return (
            <div className='item' key={index}>
              <div className='text-center mt-1 d-block fs-12 text-center' >
                <Skeleton animation="wave" variant="rectangular" width={'100%'} height={80} className='mb-2' />
                <Skeleton animation="wave" height={10} width="80%"  />
              </div>
            </div>
          )
        })
      }
    </>
  )
}

//This component use for popular package card

export function PopularPackageCard({isLoading, sliderItems, props }: any) {
  const navigation = useNavigate();

  const navigateTo = (item: any) => {
    navigation(`/compare/${item.id}`);

    const data = {
      item_id: item.id,
      item_type: 'facility-package'
    }
    _clickAnalyticsUpdate(data).then(res => console.log(res)).catch(err => console.log(err));
  };

  return (
    <>
      {
        sliderItems?.map((item: any, index: number) => {
          return (
            <button disabled={ isLoading } onClick={navigateTo.bind('e', item)} className='popular-package-card btn w-100 cursor-pointer' key={index+1}>
              <div>
                {
                  isLoading ?
                    <Skeleton animation="wave" variant="rectangular" width={'100%'} height={160} />
                  :
                  <img src={item.image_url} alt={item.name} />
                }
              </div>
              <div className='p-3'>
                {
                  isLoading ?
                  <>
                    <Skeleton animation="wave" variant="rectangular" width={'100%'}  className='mb-3 mt-1' height={14} />
                    <Skeleton animation="wave" variant="rectangular" width={'85%'}  className='mb-5' height={14} />
                    <Skeleton animation="wave" variant="rectangular" width={'95%'}  className='mb-3' height={8} />
                    <Skeleton animation="wave" variant="rectangular" width={'75%'}  className='mb-3' height={8} />
                    <Skeleton animation="wave" variant="rectangular" width={'90%'}  className='mb-2' height={8} />
                  </>
                  :
                  <>
                    <p className='fs-18 font-weight_6 mt-1 mb-2 package-heading-clamp color_black'>{item.name}</p>
                    <p className='fs-14 package-clamp color_black'>{item.notes}</p>
                  </>
                }
              </div>
            </button>
          )
        })
      }
    </>
  )
}

//Doctor date select carousel wrapper

export function CalendarCarousel(props: any) {
  return (
    <>
      <DefaultConfig {...props} content={<CalendarCard {...props} />} />
    </>
  )
}

//Banner carousel wrapper, using on payment card 

export function BannerCarousel(props: any) {
  return (
    <DefaultConfig {...props} >
      <BannerCard  {...props} loading={props.loading} />
    </DefaultConfig>
  )
}

//Home banner carousel wrapper

export function HomeBannerCarousel(props: any) {
  return (
    <DefaultConfig {...props} >
      <HomeBannerCard  {...props} loading={props.loading} />
    </DefaultConfig>
  )
}

//Specialities carousel wrapper

export function SpecialitiesCarousel({autoPlay, ...props}: any) {
  const { data } = useContext(Context)

  return (
    <DefaultConfig {...props} mainDivClss={data?.mainDivClss} autoPlay={autoPlay} >
      <SpecialitiesCard {...props} />
    </DefaultConfig>
  )
}

//Popular package & procedures carousel wrapper

export function PopularPackage({autoPlay, ...props} :any) {
  return (
    <>
      <DefaultConfig {...props} autoPlay={autoPlay} content={<PopularPackageCard {...props} />} />
    </>
  )
}

//Express otc product wrapper

export function Express({autoPlay, sliderItems, isLoading,  ...props} :any) {
  return (
    <>
      <DefaultConfig {...props} autoPlay={autoPlay} >
        {
          sliderItems?.map((item: any, index: number) => {
            return (
              <OTCCurativeCard isLoading={isLoading} item={item} action={ props } key={index} />
            )
          })
        }
      </DefaultConfig>
    </>
  )
}

export function PromotedPackageCarousel({autoPlay, sliderItems, isLoading,  ...props} :any) {
  return (
    <>
      <DefaultConfig {...props} autoPlay={autoPlay} >
        { sliderItems?.map((item: any) => {
          return (
            <PromotedPackageCard isLoading={isLoading} item={item} action={ props } key={item?.id}/>
          )
        }) }
      </DefaultConfig>
    </>
  )
}
//For Featured wrapper

export function Featured({isLoading, autoPlay, sliderItems,  ...props} :any) {

  return (
    <>
      <DefaultConfig {...props} autoPlay={autoPlay} >
        {
          sliderItems?.map((item: any, index: number) => {
            return (
              <FeaturedCard isLoading={ isLoading } item={item} key={index} action={ props } />
            )
          })
        }
      </DefaultConfig>
    </>
  )
}
