import { ButtonDefault, PageTitle } from "../../components";
import './index.scss';
import UploadIcon from '@mui/icons-material/Upload';
import { ImagingRequestIcon } from "../../assets/images";
import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { authAction } from "../../store";

const ServiceCard = ({title, icon, description, type, ...props}: any) => {
  const isAuthenticated = useSelector((state: any) => state.auth.isAuthenticated);
  const dispatch = useDispatch()
  const uploadFile = () => {
    if(!isAuthenticated) {
      dispatch(authAction.accesspermisson())
      return;
    }
    navigate(`/prescription/${type}`, {state: type})
  }
  const navigate = useNavigate();
  return (
    <div className="service-card d-flex align-items-center">
      <div className="mr-2">
        <img src={icon} alt="" />
      </div>
      <div>
        <p className="fs-15 font-weight_6 mb-1">{title}</p>
        <p className="fs-12 mb-5">{description}</p>
        <ButtonDefault 
          title={'Upload'} 
          classes={'w-100 mx-auto'} 
          icon={<UploadIcon />}
          click={uploadFile}
        />
      </div>
    </div>
  )
}
export default function PrescriptionList() {
  const dummyData = [
    {
      title: 'Imaging Request',
      type: 'imaging-request',
      icon: ImagingRequestIcon,
      description: 'Get your X-Rays, CT Scan and Ultrasound requests taken care of.',
    },
    {
      title: 'Lab Request',
      type: 'lab-request',
      icon: ImagingRequestIcon,
      description: 'Get your blood, stool and other tests requests taken care of.'
    },
    {
      title: 'Written Request',
      type: 'written-request',
      icon: ImagingRequestIcon,
      description: 'Get your prescribed medication quickly and easily.'
    },
  ]
  return (
    <div className="prescription-list">
      <PageTitle title={'Upload Written Request'} />
      <p className="fs-14 font-weight_6 mb-6">What would you like to submit ?</p>
      <Grid container spacing={2}>
        {
          dummyData.map((detail:any, index: number) => {
            return (
              <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
                <ServiceCard {...detail} />
              </Grid>
            )
          })
        }
      </Grid>
    </div>
  );
}
