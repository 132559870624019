import { Box, Grid, Skeleton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import React from 'react';
import axios from 'axios';
import { _clickAnalyticsUpdate } from '../../utils/API_SERVICE/_concierge';
import { _currencyFormatter } from '../../utils/Index';
import ButtonDefault from './Button/ButtonDefault';
import { environment } from '../../env';

//This component use for feature card

export default function FeaturedCard({ isLoading, item, action }: any) {
  const navigate = useNavigate();

  const BookClickHandler = () => {
    action.drawerOpen(item.id);
  };

  const DetailsClickHandler = () =>{
    axios.get(`${environment.service_catalogue}/package/${item.id}`)
    .then((response) => {
      let package_data = response.data.returned_resultset;
      if (package_data.facilities.length === 0) {}
      else {
        let facility_package = package_data.facilities[0];
        _clickAnalyticsUpdate({
          item_id: facility_package.id,
          item_type: 'facility-package',
        }).then(res => console.log(res)).catch(err => console.log(err));

        navigate(`/package-details/package/facility-package/${facility_package.id}`);
      }
    }).catch((error) => {
      console.log(error)
    })
  }

  return (
    <>
      <Box className="feature-main d-flex compare-card">
        <Grid item lg={7} sm={7} xs={12}>
          <div className="doctor-details pr-4">
            {
              isLoading ?
              <>
                <Skeleton animation="wave" variant="rectangular" width={'100%'}  className='mb-2' height={14} />
                <Skeleton animation="wave" variant="rectangular" width={'85%'}  className='mb-4' height={14} />
                <Skeleton animation="wave" variant="rectangular" width={'100%'}  className='mb-2' height={8} />
                <Skeleton animation="wave" variant="rectangular" width={'80%'}  className='mb-2' height={8} />
                <Skeleton animation="wave" variant="rectangular" width={'95%'}  className='mb-4' height={8} />
                <Skeleton animation="wave" variant="rectangular" width={'50%'}  className='mb-3' height={14} />
                <Skeleton animation="wave" variant="rectangular" width={'100%'} height={22} />
              </>
              :
              <>
              <h3 className='fs-18 text-left font-weight_6 package-heading-clamp'>{item.name}</h3>
              <p className="font-weight_5 fs-13 package-clamp my-3">{item.notes}</p>
              <p className="font-weight_6 fs-18 color_primary-light pb-0">{_currencyFormatter(item.min_price)}</p>
              <div className="p-0 d-flex">
                <div className="btn-section">
                  <ButtonDefault title={'Book'} 
                    classes='mt-2 w-100 mx-auto'
                    click={ BookClickHandler }
                  />

                  <ButtonDefault
                    btnClass={'bg_transparent border_primary w-100'}
                    title={'Details'}
                    classes={'ml-2 mt-2 w-100 mx-auto'}
                    click={ DetailsClickHandler }
                  />
                </div>
              </div>
              
             {/* This button use for book featured item */}
            </>
            }
          </div>
          
        </Grid>
        <Grid item lg={5} sm={5} xs={12}>
          <div className="featured_img">
            {
              isLoading ?
              <Skeleton animation="wave" variant="rectangular" width={'100%'} height={'130px'} />
              :
              <img src={item.image_url} alt={item.name} className='img-fluid' />
            }
          </div>
        </Grid>
      </Box>
    </>
  );
}
