import axios from "axios"
import { conciergeCred, environment, login, registerCred } from "../env"



export const keyclockAccessToken = (): Promise<any> => {
  const config = {
    headers: {
      "content-type": "application/x-www-form-urlencoded"
    }
  };
  const data ={
    grant_type: 'client_credentials',
    client_id: String(login.client_id),
    client_secret: String(login.client_secret)
  }

  return new Promise((resolve, reject) => {
    axios.post(`${environment.keyclock}/auth/realms/UAT/protocol/openid-connect/token`, 
      new URLSearchParams({ ...data }),
      config
    )
    .then((response: any) => {
      resolve(response)
    }).catch((err) => {
      reject(err)
    })

  })
}

export const patientAuth = () => {
  return new Promise((resolve, reject) => {
    axios.post(`${environment.patient}/auth/login`, registerCred).then((res) => resolve(res.data)).catch((err) => reject(err))
  })
}

export const conciergeAuth = () => {
  return new Promise((resolve, reject) => {
    axios.post(`${environment.concierge}/api/v1/auth/login`, conciergeCred).then((res) => resolve(res.data)).catch((err) => reject(err))
  })
}