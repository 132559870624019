import { useState, useContext } from 'react';
import {
  BuildingIcon,
  PhoneIcon,
  HouseIcon,
  PeopleSpeakingIcon,
  EmergencyIcon,
  ClockIcon,
  BookAddIcon,
  PrayingHandsIcon,
  CarOutlinedIcon,
  HospitalBedIcon,
  LikeIcon,
} from '../../../assets/images';
import { DoctorDetailsContext } from './DoctorDetailsPage';
import { arrJoin } from '../../../utils/StringHandler/arrayJoin';

function About() {
  const [showAdditionalInfo, setShowAdditionalInfo] = useState(true);
  const { docData } = useContext(DoctorDetailsContext);

  const toggleAdditionalInfo = () => {
    setShowAdditionalInfo(!showAdditionalInfo);
  };

  const combinedArrayValues = (arr: any) => {
    if (arr?.length) {
      let newArr = arr?.map((item: any) => {
        return item?.name;
      });
      return arrJoin(newArr);
    }
    return ["None"]
  };

  return (
    <div className="about-section">
      <div className="about-item">
        <div className="about-item-name">Speciality</div>
        <div className="about-item-details">{docData?.speciality?.title}</div>
      </div>
      <div className="about-item">
        <div className="about-item-name">Gender</div>
        <div className="about-item-details">{docData?.gender?.name}</div>
      </div>
      <div className="about-item">
        <div className="about-item-name">Education</div>
        <div className="about-item-details">
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {docData?.qualifications &&
              docData?.qualifications?.map((item: any, index: number) => {
                return <div key={index}>{item?.qualification}</div>;
              })
            }
            {docData?.qualifications.length === 0 ? "Not Specified" : null}
          </div>
        </div>
      </div>
      <div className="about-item">
        <div className="about-item-name">Doctor Reg No</div>
        <div className="about-item-details">{docData?.reg_no}</div>
      </div>
      <div className="about-item">
        <div className="about-item-name">Services Type</div>
        <div className="about-item-details">
          <div className="service-type-section">
            {docData?.facility_rate > 0 ? (
              <div className="service-type-item mb-1">
                <img className="about-details-icon" src={BuildingIcon} alt="" />
                <span className="service-type-name">Facility Visit</span>
              </div>
            ) : null}
            {docData?.home_rate > 0 ? (
              <div className="service-type-item mb-1">
                <img className="about-details-icon" src={HouseIcon} alt="" />
                <span className="service-type-name">Home Visit</span>
              </div>
            ) : null}
            {docData?.teleconsult_rate > 0 ? (
              <div className="service-type-item">
                <img className="about-details-icon" src={PhoneIcon} alt="" />
                <span className="service-type-name">Teleconsultation</span>
              </div>
            ) : null}
          </div>
        </div>
      </div>
      <div className="about-item">
        <div className="about-item-name">Areas of Interest</div>
        <div className="about-item-details">
          <div className="service-type-section">
            {docData?.interests &&
              docData?.interests?.map((item: any, index: number) => {
                return <div key={index} className="service-type-item"><img className="about-details-icon mb-1" src={LikeIcon} alt="" /><span className="service-type-name">{item?.interest}</span></div>;
              })
            }
            {docData?.interests.length === 0 ? "Not Specified": null }
          </div>
        </div>
      </div>
      <div className="about-item">
        <div className="about-item-name">
          <div className="language-title-section">
            <img
              className="about-details-icon"
              src={PeopleSpeakingIcon}
              alt=""
            />
            <span className="language-title">Languages</span>
          </div>
        </div>
        <div className="about-item-details">
          {docData?.language?.length
            ? arrJoin(docData?.language)
            : 'Not Specified'}
        </div>
      </div>
      <div className="additional-info-item">
        <div className="additional-info-header-section mt-5">
          <div className="doctor-name">Additional Details</div>
          <div className="about-item-details">
            <div className="show-all-btn-section">
              <span className="show-all-btn pt-1" onClick={toggleAdditionalInfo}>
                {!showAdditionalInfo ? "Show all" : "Hide all"}                
              </span>
            </div>
          </div>
        </div>
        {showAdditionalInfo && (
          <div className="additional-details-items-section">
            <div className="additional-details-item">
              <div className="additional-details-item-name-section">
                <img
                  className="about-details-icon"
                  src={EmergencyIcon}
                  alt=""
                />
                <span className="additional-details-item-name">
                  Available for Emergency
                </span>
              </div>
              <div className="about-item-details">
                {docData?.emergency_rate ? 'Yes' : 'No'}
              </div>
            </div>

            <div className="additional-details-item">
              <div className="additional-details-item-name-section">
                <img className="about-details-icon" src={ClockIcon} alt="" />
                <span className="additional-details-item-name">
                  Guaranteed wait time
                </span>
              </div>
              <div className="about-item-details">
                {docData?.wait_time
                  ? `${docData?.wait_time} Minutes`
                  : '30 Minutes'}
              </div>
            </div>

            <div className="additional-details-item">
              <div className="additional-details-item-name-section">
                <img className="about-details-icon" src={BookAddIcon} alt="" />
                <span className="additional-details-item-name">
                  Private Insurance affiliation
                </span>
              </div>
              
              <div className="about-item-details">
                {docData?.insurance
                  ? combinedArrayValues(docData?.insurance)
                  : 'None'}
              </div>
            </div>

            <div className="additional-details-item">
              <div className="additional-details-item-name-section">
                <img
                  className="about-details-icon"
                  src={PrayingHandsIcon}
                  alt=""
                />
                <span className="additional-details-item-name">Faith</span>
              </div>
              <div className="about-item-details">
                {docData?.faith?.name ? docData?.faith?.name : 'N/A'}
              </div>
            </div>

            <div className="additional-details-item">
              <div className="additional-details-item-name-section">
                <img
                  className="about-details-icon"
                  src={CarOutlinedIcon}
                  alt=""
                />
                <span className="additional-details-item-name">Amenities</span>
              </div>
              <div className="about-item-details">
                {docData?.amenities
                  ? combinedArrayValues(docData?.amenities)
                  : 'None'}
              </div>
            </div>

            <div className="additional-details-item">
              <div className="additional-details-item-name-section">
                <img
                  className="about-details-icon"
                  src={HospitalBedIcon}
                  alt=""
                />
                <span className="additional-details-item-name">
                  Admitting rights
                </span>
              </div>
              <div className="about-item-details">
                {docData?.admissions?.length
                  ? arrJoin(docData?.admissions)
                  : 'None'
                }
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default About;
