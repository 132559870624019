import { Box, TextField } from '@mui/material';
import axios from 'axios';
import React, { FormEvent, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { Logo, SuccessTick } from '../../assets/images';
import { PageTitle, DragAndDropInput, ButtonDefault, CustomModal } from '../../components';
import { environment } from '../../env';
import { conciergeAuth } from '../../utils/tokens';

const SuccessInfo = (props: any) => {
  return (
    <>
      <div className='d-flex flex-column align-items-center p-8 text-center'>
        <img src={Logo} className='site-logo' alt="" />
        <img src={SuccessTick} className='my-4' width='75px' alt="" />
        <h3 className='fs-20 mb-4'>Thank You</h3>
        <p>Your file upload is successfull. <br/> Ponea Health team will get back to you soon.</p>
        <ButtonDefault title='Okay' click={props.click} classes='w-100 mt-8' />
      </div>
    </>
  )
}
export default function UploadPrescription(props:any) {
  const [successInfo, setSuccessInfo] = useState<boolean>(false);
  const navigation = useNavigate()

  const [values, setValues] = useState({
    narration: '',
    files: File,
    patient_uuid: JSON.parse(localStorage.getItem('userID') || '0'),
    request_source: 'Market place'
  });

  const handleChange = (prop: any) => (event: any) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const [inputFileName, setInputFileName] = useState<string>('');

  const handleFileChange = (e: any) => {
    if(e?.length) {
      setInputFileName(e[0].name);
      setValues({
        ...values,
        files: e
      })
    }
  }

  const handleSuccessModal = () => {
    setSuccessInfo(!successInfo)
  }

  const [loading, setLoading] = useState<boolean>(false);
  const submitData = async(ev: FormEvent) => {
    try {
      ev.preventDefault();
      // creating form data
      const formData = new FormData();
      for(let [key, item] of Object.entries(values)) {
        if(key === 'files') {
          Array.from(item).forEach((file: any) => {
            formData.append(key, file);
          })
        }else {
          formData.append(key, item);
        }
      }

      setLoading(true);
      const token: any = await conciergeAuth();
      axios.post(`${environment.concierge}/api/v1/request`, formData, {
        headers: {
          authorization: `Bearer ${token.access_token}`,
          user: token.client.id
        }
      }).then((response) => {
        setLoading(false);
        setValues({
          narration: '',
          files: File,
          patient_uuid: JSON.parse(localStorage.getItem('userID') || '0'),
          request_source: 'Market place'
        })
        handleSuccessModal();
        setInputFileName('')
      }).catch((err) => {
        setLoading(false);
      });
    } catch (error) {
      console.log(error)
      setLoading(false);
    }
  }
  const { prescription_type } = useParams();
  const [endPoint, setEndPoint] = useState<string>('');

  useEffect(() => {
    if(prescription_type === 'lab-request') {
      setEndPoint('/categories/lab-tests')
    }else if(prescription_type === 'imaging-request') {
      setEndPoint('/categories/imaging')
    }else {
      setEndPoint('/doctor-list')
    }
  }, [prescription_type]);
  return (
    <>
      <PageTitle title={'Submit Prescription'} />
      <p className="fs-14 font-weight_6 mb-5">Upload your { prescription_type?.replaceAll('-', ' ') }</p>
      <Box 
        component={'form'}
        sx={{
          marginTop: 2,
          maxWidth: 500,
          mx: 'auto',
          width: '100%',
          '& .MuiTextField-root': {
            my: 3,
            width: '100%'
          }
        }}
        onSubmit={submitData}
      >
        <p className='mb-3'>Upload here</p>
        <DragAndDropInput change={handleFileChange} fileName={inputFileName} />
        <TextField
          label="Additional Information"
          id="narration"
          value={values.narration}
          variant="outlined"
          sx={{width: '100%', margin: '12px 0'}}
          onChange={handleChange('narration')}
          multiline
          minRows={2}
        />
        <ButtonDefault 
          type='submit'
          title={'Proceed'} 
          btnClass='py-2 w-100 btn_primary color_white' 
          classes='w-100% my-auto' 
          loading={loading}
        />
      </Box>
      <p className='my-4 font-weight_5 text-center'>Don’t have { prescription_type?.replaceAll('-', ' ') }?</p>
      <ButtonDefault 
        title={' Book Consultation '}
        classes='py-0 mt-1 my-auto w-100'
        btnClass={'bg_transparent border_primary w-100'}
        click={() => navigation(endPoint)}
      />

      <CustomModal open={ successInfo } onCloseModal={ handleSuccessModal } content={ <SuccessInfo click={handleSuccessModal} /> } />
    </>
  )
}
