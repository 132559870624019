import { useEffect, useState } from "react";
import axios from "axios";
import { environment } from "../../env";
import { Grid } from "@mui/material";
import { NavLink } from 'react-router-dom';
import { SuperSEO } from "react-super-seo";
import { pageSEO } from "../../seo";

const Card = ({id,image_url, title, ...pros}: any) => {
  return (
    <>
      <NavLink to={`/categories/${title.replaceAll(' ', '-').toLowerCase()}`} className='text-center mb-2'>
        <div className="icon-container bg_primary-gradient">
          <img src={image_url} alt="" />
        </div>
        <p>{title}</p>
      </NavLink>
    </>
  )
}

export default function SpecialitiesList() {
  const [specialitiesData, setSpecialitiesData] = useState<any>({
    loading: true,
    data: new Array(5).fill(''),
    isError: false,
    isCalled: false,
    isData: false
  });

  useEffect(() => {
    getSpecialities();
  }, []);

  const getSpecialities = () => {
    setSpecialitiesData({
      loading: true,
      isCalled: true,
      data: new Array(5).fill(''),
    })
    axios.get(environment.service_catalogue + '/speciality')
      .then((response) => {
        const finalData = response['data']['returned_resultset'].map((item: any) => ({
          ...item
        }))
        setSpecialitiesData({
          loading: false, 
          data: finalData, 
          isCalled: false,
          isData: true
        })
      }).catch((error) => {
        setSpecialitiesData({
          loading: false, 
          data: [], 
          isCalled: true, 
          isError: true,
          errorMssg: error.message
        });
      })
  }


  return (
    <>
      <SuperSEO
        title={pageSEO.specialities.title}
        description={pageSEO.specialities.description}
      />
      <p className='color_primary-light font-weight_5 mb-4 home-title-heading'>All Specialities</p>
      <Grid container spacing={2} mt={1.5}>
        {
          !specialitiesData.loading ?
          <>
            {
              specialitiesData.data?.map((item: any, index: number) => {
                return (
                  <Grid item xs={6} sm={4} md={3} lg={1.714285714} key={index}>
                    <Card {...item}></Card>
                  </Grid>
                )
              })
            }
          </>
          :
          <Grid item xs={12}>
            <p>Loading... </p>
          </Grid>
        }
      </Grid>
    </>
  );
}
