import { Skeleton } from '@mui/material';
import React from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ProductDetailsPlaceHolder } from '../../assets/images';
import { _clickAnalyticsUpdate } from '../../utils/API_SERVICE/_concierge';
import { _currencyFormatter } from '../../utils/Index';
import { environment } from '../../env';
import ButtonDefault from './Button/ButtonDefault';

type CardProps ={
  isLoading: boolean,
  item: any,
  action: any
};

//This component use for express otc product

export default function OTCCurativeCard({isLoading, item, action}: CardProps) {
  const navigate = useNavigate();
  const isAuthenticated = useSelector((state: any) => state.auth.isAuthenticated);

  const itemClickHandler = () => {
    axios.get(`${environment.service_catalogue}/package/${item.id}`)
    .then((response) => {
      let package_data = response.data.returned_resultset;
      if (package_data.facilities.length === 0) {
        action?.drawerOpen(item.id);
      }
      else {
        let facility_package = package_data.facilities[0];
        _clickAnalyticsUpdate( {
          item_id: facility_package.id,
          item_type: 'facility-package',
        }).then(res => console.log(res)).catch(err => console.log(err));

        navigate(`/package-details/package/facility-package/${facility_package.id}`);
      }
    }).catch((error) => {
      console.log(error)
    })
  }

  const bookClickHandler = () => {
    action?.drawerOpen(item.id);
    _clickAnalyticsUpdate( {
      item_id: item.id,
      item_type: 'package',
    }).then(res => console.log(res)).catch(err => console.log(err));
  }

  return (
    <>
      <div className='popular-package-card btn w-100 d-flex flex-column'>
        <div className='OTC-img-container'>
          {
            isLoading ?
            <Skeleton animation="wave" variant="rectangular" width={'100%'} height={'100%'} />
            :
            <img 
              src={item.image_url ? item.image_url : ProductDetailsPlaceHolder} 
              style={action.type !== 'otc-express' ? {objectFit: 'cover'} : {} } 
              alt={item.name || item.description} 
            />
          }
        </div>
        <div className='p-3 h-100 d-flex flex-column'>
          {
            isLoading ?
            <>
              <Skeleton animation="wave" variant="rectangular" width={'100%'}  className='mb-3 mt-1' height={16} />
              <Skeleton animation="wave" variant="rectangular" width={'85%'}  className='mb-2' height={8} />
              <Skeleton animation="wave" variant="rectangular" width={'50%'}  className='mb-3' height={8} />
              <Skeleton animation="wave" variant="rectangular" width={'100%'}  className='mb-1 mt-3' height={20} />
            </>
            :
            <>
              <p className='fs-17 font-weight_6 my-1 package-heading-clamp'>{item.name || item.description}</p>
              <p className="font-weight_6 fs-18 color_primary-light mt-auto">{_currencyFormatter(item.min_price)}</p>
              <div>
                {
                  action.type === 'otc-express' ? 
                  <>
                    <ButtonDefault title={'Details'} 
                      classes='mt-2 w-100 mx-auto'
                      click={() => {
                        navigate(`/products/${item.description.replaceAll(' ','-').replaceAll('/','-').toLowerCase()}`)
                        const data = {
                          item_id: item.id,
                          item_type: 'facility-package',
                        };
                        _clickAnalyticsUpdate( data ).then(res => console.log(res)).catch(err => console.log(err));
                      }}
                    />
                  </>
                  : 
                  <>
                    <ButtonDefault
                      btnClass={'bg_transparent border_primary w-100'}
                      title={'Details'}
                      classes={'w-100 mt-5 mx-auto'}
                      click={ itemClickHandler }
                    />
                    {
                      isAuthenticated && (
                        <ButtonDefault title={'Book'} 
                          classes='mt-2 w-100 mx-auto'
                          click={ bookClickHandler }
                        />
                      )
                    }
                  </>
                }
              </div>
            </>
          }
        </div>
      </div>
    </>
  );
}
