import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Skeleton } from '@mui/material';
import { ProductDetailsPlaceHolder } from '../../assets/images';
import { Divider } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import {
  checkWishlistItems,
  removeItemFromWishlist,
  wishlistHandler,
} from '../../utils/WishlistHandler';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import CircularProgress from '@mui/material/CircularProgress';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { _clickAnalyticsUpdate } from '../../utils/API_SERVICE/_concierge';
import { _currencyFormatter } from '../../utils/Index';
import { authAction, cartAction } from '../../store';
import { useHits } from 'react-instantsearch-hooks-web';
import { useNavigate } from 'react-router-dom';
import { pathBuilder } from '../../urlPaths';

type PromotedPackagePropType = {
  isLoading: boolean;
  item: any;
  action: any;
};

function PromotedPackageCard({
  isLoading,
  item,
  action,
}: PromotedPackagePropType) {
  const [wishlistState, setWishlistState] = useState<boolean>(false);
  const [wishlistLoading, setWishlistLoading] = useState<boolean>(false);
  const [apiError, setApiError] = useState<string>('');

  const [cartQty, setCartQty] = useState<number>(1);

  const isAuthenticated = useSelector(
    (state: any) => state.auth.isAuthenticated
  );

  const { hits, results } = useHits();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const itemClickHandler = () => {
    if (isAuthenticated) {
      action?.drawerOpen(item.id);
      action?.setItem(item);

      const data = {
        item_id: item.id,
        item_type: item.item_details.item_endpoint,
      };
      _clickAnalyticsUpdate(data)
        .then((res) => console.log(res))
        .catch((err) => console.log(err));
    } else {
      dispatch(authAction.accesspermisson());
    }
  };

  const tempArr = [
    item?.rates?.facility_visit,
    item?.rates?.home_visit,
    item?.rates?.teleconsult,
    item?.rates?.emergency_rate,
  ];

  const minPrice = Math.min.apply(null, tempArr.filter(Boolean));

  const slashedPrice = minPrice + 1000;

  const addToWishlist = (data: any) => {
    if (isAuthenticated) {
      setWishlistLoading(true);
      if (!wishlistState) {
        wishlistHandler(data, cartQty, data.item_details.item_type)
          .then((response) => {
            setWishlistState(!wishlistState);
            setWishlistLoading(false);
          })
          .catch((error) => {
            setWishlistLoading(false);
            setWishlistLoading(false);
            if (error?.response) {
              setApiError(error.response.data.msg);
            } else {
              setApiError(error.message);
            }
          });
      } else {
        removeItemFromWishlist(data.id)
          .then((response) => {
            setWishlistState(!wishlistState);
            setWishlistLoading(false);
          })
          .catch((error) => {
            console.log(error);
            setWishlistLoading(false);
            if (error?.response) {
              setApiError(error.response.data.msg);
            } else {
              setApiError(error.message);
            }
          });
      }
    } else {
      dispatch(authAction.accesspermisson());
    }
  };

  useEffect(() => {
    if (results!.processingTimeMS > 0) checkWishlist();
  }, [hits]);

  // Checking is this item is exist in wishlist
  const checkWishlist = async () => {
    setWishlistLoading(true);
    const response = await checkWishlistItems(String(item?.id));
    setWishlistLoading(false);
    if (response) {
      setWishlistState(true);
    }
  };

  const goToDetailsPage = (itemId: string, itemCompareEndpoint: string, itemEndpoint: string) => {
    const data = {
      item_id: item.id,
      item_type: item.item_details.item_endpoint,
    };

    _clickAnalyticsUpdate(data)
      .then((res) => {})
      .catch((err) => {});

    navigate(pathBuilder.packageDetails(itemId, itemCompareEndpoint, itemEndpoint));
  };

  return (
    <div
      style={{ marginBottom: '20px' }}
      className="promoted-package-card shadow-lg d-flex flex-column"
      key={item?.id}
    >
      <div className="promoted-img-container">
        {isLoading ? (
          <Skeleton
            animation="wave"
            variant="rectangular"
            width={'100%'}
            height={'100%'}
          />
        ) : (
          <img
            src={
              item.images.main ? item.images.main : ProductDetailsPlaceHolder
            }
            alt={item.name || 'promoted packages'}
          />
        )}
      </div>

      <div className="p-3 d-flex flex-column">
        {isLoading ? (
          <>
            <Skeleton
              animation="wave"
              variant="rectangular"
              width={'40%'}
              className="mb-3 mt-1"
              height={10}
            />
            <Skeleton
              animation="wave"
              variant="rectangular"
              width={'85%'}
              className="mb-2"
              height={8}
            />
            <Skeleton
              animation="wave"
              variant="rectangular"
              width={'50%'}
              className="mb-3"
              height={8}
            />
          </>
        ) : (
          <div className="h-100">
            <p className="fs-17 font-weight_6 my-1 package-heading-clamp">
              {item.name}
            </p>
            <p
              style={{ color: 'grey' }}
              className="fs-15 my-1 package-details-clamp"
            >
              {item.notes}
            </p>
          </div>
        )}
      </div>

      <Divider light={true} variant="fullWidth" />

      <div className="p-3 d-flex flex-column">
        <div
          style={{ alignItems: 'center' }}
          className="d-flex justify-content-between"
        >
          <div
            style={{ alignItems: 'center' }}
            className="d-flex justify-content-between"
          >
            {isLoading ? (
              <>
                <Skeleton
                  animation="wave"
                  variant="rectangular"
                  width={'40%'}
                  className="mb-2"
                  height={8}
                />
              </>
            ) : (
              <>
                <div className="font-weight_6 fs-15 color_primary-light">
                  {_currencyFormatter(minPrice)}
                </div>
                <div
                  style={{
                    color: 'grey',
                    textDecoration: 'line-through',
                    marginLeft: '4px',
                  }}
                  className="fs-9"
                >
                  {_currencyFormatter(slashedPrice)}
                </div>
              </>
            )}
          </div>
          {isLoading ? (
            <>
              <Skeleton
                animation="wave"
                variant="rectangular"
                width={'30%'}
                className="mb-2"
                height={8}
              />
            </>
          ) : (
            <p
              style={{ color: 'grey', textAlign: 'end', marginLeft: '5px' }}
              className="fs-10"
            >
              {item.translations[0].short_description ?? ''}
            </p>
          )}
        </div>
      </div>

      <div className="p-3 d-flex">
        <div className="btn-section">
          {isLoading ? (
            <>
              <Skeleton
                animation="wave"
                variant="rectangular"
                width={'40%'}
                className="mb-2"
                height={8}
              />
              <Skeleton
                animation="wave"
                variant="rectangular"
                width={'40%'}
                className="mb-3"
                height={8}
              />
            </>
          ) : (
            <>
              <button
                onClick={addToWishlist.bind('ev', item)}
                className="btn wishlist-btn"
              >
                {wishlistLoading ? (
                  <CircularProgress style={{ padding: '5px' }} />
                ) : wishlistState ? (
                  <>
                    <span style={{ display: 'flex' }}>
                      <FavoriteIcon />
                    </span>
                    <span
                      style={{
                        marginLeft: '2px',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                      }}
                    >
                      Remove From Wishlist
                    </span>
                  </>
                ) : (
                  <>
                    <span style={{ display: 'flex' }}>
                      <FavoriteBorderOutlinedIcon />
                    </span>
                    <span
                      style={{
                        marginLeft: '2px',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                      }}
                    >
                      Add To Wishlist
                    </span>
                  </>
                )}
              </button>

              <button
                onClick={() => {
                  goToDetailsPage(
                    item?.id,
                    item?.item_details?.compare_endpoint,
                    item?.item_details?.item_endpoint
                  );
                }}
                className="btn book-now-btn"
              >
                <span style={{ display: 'flex' }}>
                  <ShoppingCartOutlinedIcon />
                </span>
                <span
                  style={{
                    marginLeft: '2px',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                  }}
                >
                  Book Now
                </span>
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default PromotedPackageCard;
