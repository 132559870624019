export const paths = {
  promotedPackages: 'promoted-packages',
  packageDetails: '/package-details/:itemCompareEndpoint/:itemEndpoint/:itemId',
  serviceDetails: '/service-details/:itemCompareEndpoint/:itemEndpoint/:itemId',
  compare: '/compare/:itemCompareEndpoint',
  products: '/products/:slug',
  doctorDetails: '/doctor-details/:doctorName/:doctorId',
};

export const pathBuilder = {
  packageDetails: (
    itemId: string,
    itemCompareEndpoint: string,
    itemEndpoint: string
  ) => `/package-details/${itemCompareEndpoint ?? "package"}/${itemEndpoint ?? "facility-package"}/${itemId}`,
  serviceDetails: (
    itemId: string,
    itemCompareEndpoint: string,
    itemEndpoint: string
  ) => `/service-details/${itemCompareEndpoint ?? "service"}/${itemEndpoint ?? "facility-service"}/${itemId}`,
  compare: (
    itemCompareEndpoint: string
  ) => `/compare/${itemCompareEndpoint}`,
  products: (
    slug: string
  ) => `/products/${slug}`,
  doctorDetails: (
    doctorName: string,
    doctorId: string
  ) => `/doctor-details/${doctorName.replaceAll('.', '').replaceAll(' ', '-').toLowerCase()}/${doctorId}`,
};
