import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, FormHelperText, Grid, Skeleton } from '@mui/material';
import { ProductDetailsPlaceHolder, RatingStar } from '../../assets/images';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import {
  Configure,
  InstantSearch,
  useHits,
} from 'react-instantsearch-hooks-web';
import algoliasearch from 'algoliasearch';
import { algolia, environment } from '../../env';
import CartHandler from '../../utils/CartHandler';
import {
  checkWishlistItems,
  removeItemFromWishlist,
  wishlistHandler,
} from '../../utils/WishlistHandler';
import { authAction, cartAction } from '../../store';
import { SuperSEO } from 'react-super-seo';
import { pageSEO } from '../../seo';
import { LoadingButton } from '@mui/lab';
import { ButtonDefault, MyIconButton } from '../../components';
import { _currencyFormatter } from '../../utils/Index';
import CartQtyUpdate from '../../components/commons/CartQtyUpdate';

//this component use for show, add or remove to wishlist, add to cart of products data

const Card = () => {
  const isAuthenticated = useSelector(
    (state: any) => state.auth.isAuthenticated
  );
  const { healthcare_type_id } = useParams();

  const [loading, setLoading] = useState<boolean>(false);
  const [cartQty, setCartQty] = useState<number>(1);

  const { hits, results } = useHits();
  const dispatch = useDispatch();

  let productData: any = hits[0];
  const filterItem = hits.filter(
    (item: any) => item.slug === healthcare_type_id
  );

  if (filterItem.length) {
    productData = filterItem[0];
  }

  const increaseItemHandler = () => {
    setCartQty(cartQty + 1);
  };

  const decreaseItemHandler = () => {
    setCartQty(cartQty - 1);
  };

  const [itemViewToggle, setitemViewToggle] = useState<boolean>(false);
  const viewMoreHandler = () => setitemViewToggle(!itemViewToggle);
  const [wishlistState, setWishlistState] = useState<boolean>(false);
  const [wishlistLoading, setWishlistLoading] = useState<boolean>(false);
  const [apiError, setApiError] = useState<string>('');

  //item add to wishlist

  const addToWishlist = (data: any) => {
    if (isAuthenticated) {
      setWishlistLoading(true);
      if (!wishlistState) {
        wishlistHandler(data, cartQty, data.item_details.item_type)
          .then((response) => {
            setWishlistState(!wishlistState);
            setWishlistLoading(false);
          })
          .catch((error) => {
            setWishlistLoading(false);
            setWishlistLoading(false);
            if (error?.response) {
              setApiError(error.response.data.msg);
            } else {
              setApiError(error.message);
            }
          });
      } else {
        //item remove from wishlist

        removeItemFromWishlist(data.id)
          .then((response) => {
            setWishlistState(!wishlistState);
            setWishlistLoading(false);
          })
          .catch((error) => {
            console.log(error);
            setWishlistLoading(false);
            if (error?.response) {
              setApiError(error.response.data.msg);
            } else {
              setApiError(error.message);
            }
          });
      }
    } else {
      dispatch(authAction.accesspermisson());
    }
  };

  const [cartErr, setCartErr] = useState<string>('');
  const cartHandler = (data: any) => {
    setLoading(true);
    setCartErr('');
    CartHandler(data, cartQty, data.item_details?.item_endpoint)
      .then((response: any) => {
        setLoading(false);
        if (!response?.data.isExistingItem) {
          dispatch(cartAction.addCart());
        }
        setCartQty(1);
      })
      .catch((error) => {
        setCartErr(
          error?.response?.data?.msg ||
            error?.message ||
            'Something went wrong, Please try again later.'
        );
        console.log(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (results!.processingTimeMS > 0) checkWishlist();
  }, [hits]);

  // Checking is this item is exist in wishlist

  const checkWishlist = async () => {
    setWishlistLoading(true);
    const response = await checkWishlistItems(String(productData?.id));
    setWishlistLoading(false);
    if (response) {
      setWishlistState(true);
    }
  };

  //render the quantity button

  const cartUpdatedToggle = useSelector(
    (state: any) => state.cart.cartUpdatedToggle
  );
  useEffect(() => {
    setLoading(!loading);
  }, [cartUpdatedToggle]);

  return (
    <>
      {results!.processingTimeMS <= 0 ? (
        <>
          <Grid item xs={12} sm={6} md={4}>
            <div className="text-center">
              <Skeleton
                animation="wave"
                variant="rectangular"
                width={'100%'}
                height={'250px'}
              />
              <Skeleton
                animation="wave"
                variant="rectangular"
                width={'50%'}
                height={'50px'}
                className="mt-3 mx-auto"
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={8}>
            <div>
              <Skeleton
                animation="wave"
                variant="rectangular"
                width={'100%'}
                height={'35px'}
              />
              <Skeleton
                animation="wave"
                variant="rectangular"
                width={'50%'}
                height={'20px'}
                className="mt-3"
              />
              <Skeleton
                animation="wave"
                variant="rectangular"
                width={'75%'}
                height={'15px'}
                className="mt-5"
              />
              <Skeleton
                animation="wave"
                variant="rectangular"
                width={'65%'}
                height={'25px'}
                className="mt-2"
              />
              <Skeleton
                animation="wave"
                variant="rectangular"
                width={'100%'}
                height={'15px'}
                className="mt-4"
              />
              <Skeleton
                animation="wave"
                variant="rectangular"
                width={'40%'}
                height={'50px'}
                className="mt-3 mx-auto"
              />
            </div>
          </Grid>
        </>
      ) : (
        <></>
      )}
      {results!.processingTimeMS > 0 && !hits.length ? (
        <Grid item xs={12} sm={6} md={4}>
          No data found.
        </Grid>
      ) : (
        ''
      )}
      {results!.processingTimeMS > 0 && hits.length ? (
        <>
          <Grid item xs={12} sm={6} md={4}>
            <div>
              <img
                src={
                  productData.images?.main
                    ? productData.images?.main
                    : ProductDetailsPlaceHolder
                }
                style={{ maxHeight: '100%', height: 350, objectFit: 'contain' }}
                className="img-fluid"
                alt=""
              />
              <div className="text-center my-3">
                {apiError && (
                  <FormHelperText error className="text-center my-2">
                    {apiError}
                  </FormHelperText>
                )}
                <LoadingButton
                  variant="outlined"
                  startIcon={
                    wishlistState ? <FavoriteIcon /> : <FavoriteBorderIcon />
                  }
                  onClick={addToWishlist.bind('ev', productData)}
                  loading={wishlistLoading ? true : false}
                >
                  <span className="wishlist-text-container">
                    {wishlistState ? 'Remove from ' : 'Add to '}
                    wishlist
                  </span>
                </LoadingButton>
              </div>
              <p>Price</p>
              <p className="home-title-heading font-weight_6 color_primary-light">
                {_currencyFormatter(productData.rates?.facility_visit)}
              </p>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={8}>
            <div>
              <p className="home-title-heading font-weight_6">
                {productData.name}
              </p>
              <p className="fs-18 color_black85 mb-4">
                {String(productData.group?.name)}
              </p>
              {productData.rating?.score > 0 && (
                <>
                  <p className="fs-16 color_black75">Ponea Ratings</p>
                  <div className="d-flex align-items-center">
                    <img src={RatingStar} alt="" />
                    <p className="ml-2 fs-18 font-weight_6">
                      {productData?.rast_rating}
                    </p>
                  </div>
                </>
              )}
              <p className="color_black75 mt-5">About Product</p>
              <p>{productData?.notes}</p>
              <p className="fs-15 font-weight_5 color_black75">
                Detailed Description{' '}
                <MyIconButton
                  click={viewMoreHandler}
                  icon={
                    !itemViewToggle ? (
                      <KeyboardArrowDownRoundedIcon />
                    ) : (
                      <KeyboardArrowUpRoundedIcon />
                    )
                  }
                />
              </p>
              {/* Details more view */}
              {itemViewToggle && (
                <div>
                  <p>{productData?.description}</p>
                  {productData?.requestor && (
                    <div className="qa-container mt-2">
                      <p className="font-weight_5">Requestor</p>
                      <p>{productData?.requestor}</p>
                    </div>
                  )}
                  {productData?.tat && (
                    <div className="qa-container mt-2">
                      <p className="font-weight_5">Tat</p>
                      <p>{productData?.tat}</p>
                    </div>
                  )}
                  {productData?.collection && (
                    <div className="qa-container mt-2">
                      <p className="font-weight_5">Collect at</p>
                      <p>{productData?.collection.replaceAll(',', ', ')}</p>
                    </div>
                  )}
                </div>
              )}

              {/* render the CartQtyUpdate componenet & pass the children from here */}

              <div style={{ maxWidth: 300 }}>
                <CartQtyUpdate productID={productData.id} isUpdate={loading}>
                  <div className="d-flex align-items-center mt-6">
                    <p className="mr-2">Quantity</p>
                    <Button
                      type="button"
                      variant="outlined"
                      className=""
                      disabled={cartQty <= 1}
                      onClick={decreaseItemHandler}
                    >
                      <RemoveOutlinedIcon />
                    </Button>
                    <input
                      type="number"
                      value={cartQty}
                      onChange={() => cartQty}
                      readOnly
                      className="quantity-input"
                    />
                    <Button
                      type="button"
                      variant="outlined"
                      className=""
                      onClick={increaseItemHandler}
                    >
                      <AddOutlinedIcon />
                    </Button>
                  </div>
                  <ButtonDefault
                    click={cartHandler.bind('ev', productData)}
                    loading={loading}
                    disabled={loading}
                    classes="mt-6"
                    title={'Add to cart'}
                  />
                  {cartErr && (
                    <FormHelperText error className="text-center mt-2">
                      {cartErr}
                    </FormHelperText>
                  )}
                </CartQtyUpdate>
              </div>
            </div>
          </Grid>
        </>
      ) : (
        ''
      )}
    </>
  );
};

export default function HelthcareCategoriesDetails(props: any) {
  const { healthcare_type_id } = useParams();
  const pageTitle: string | any = healthcare_type_id;

  const searchClient = algoliasearch(algolia.appId, algolia.apiKey);

  type TPageSeo = keyof typeof pageSEO.products;
  let seoItems = healthcare_type_id as TPageSeo;

  return (
    <>
      <SuperSEO
        title={
          pageSEO.products[seoItems]
            ? pageSEO.products[seoItems]?.title
            : pageSEO.product.title
        }
        description={
          pageSEO.products[seoItems]
            ? pageSEO.products[seoItems]?.description
            : pageSEO.product.description
        }
      />

      <Grid container spacing={2} mt={3}>
        <InstantSearch
          searchClient={searchClient}
          indexName={environment.algoliaIndex || 'product_index_newest'}
          searchFunction={(helper) => {
            helper.setQuery(pageTitle).search();
          }}
        >
          <Configure
            hitsPerPage={200}
            distinct={true}
            restrictSearchableAttributes={['slug', 'id']}
          />
          <Card />
        </InstantSearch>
      </Grid>
    </>
  );
}
